.releases-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.releases-header-container p {
    width: 47rem;
}

.releases-common-layout__container {
    margin-top: 2rem;
}

.releases-common-layout__container > div {
    padding-bottom: 2rem;
}

.releases-header-wrapper {

}

.releases-date-versions-wrapper {
    display: flex;
    align-items: center;
}

.releases-versions-wrapper {
  display: flex;
  height: 28px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 64px;
  border: 1px solid var(--Black, #2A2A2A);
}

.releases-date-versions-wrapper > div {
    margin: 0 1rem 0 0;
}

.date-versions-separator {
    width: 1px;
    height: 43px;
    background: #000000;
}


.releases-layout-header-wrapper h2 {
    margin-top: .3rem;
}

.releases-layout-header-wrapper p {
   width: 56rem;
   margin-top: .9rem;
}
