.terms-of-use-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
  }


  .terms-of-use-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60rem;
  }

  @media (max-width: 960px) {
    .terms-of-use-header-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    .header-terms-title-mobile-none {
        display: none;
    }
}


