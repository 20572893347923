.functionalities-comp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.squib-teaser-img {
  width: 86.125rem;
  height: 48.4375rem;
}

.chevron-up-icon-wrapper {
  display: none;
}

@media (max-width: 1285px) {
  .chevron-up-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #F4F5FB;
    overflow: hidden;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .functionalities-header-img-wrapper {
    margin-top: 3.2rem;
  }

  .squib-teaser-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 960px) {
  .functionalities-comp-container {
    margin-top: 7rem;
  }
}
