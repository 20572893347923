.translation-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5rem;
  margin-right: -5rem;
}

.world-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.de-btn {
  font-family: PublicSans-Bold;
  margin-right: 1rem;
  font-weight: 400;
}

.en-btn {
  font-family: PublicSans-Bold;
  margin-left: 1rem;
  font-weight: 400;
}

.translation-btn-wrapper button {
  border: none;
  background-color: transparent;
  cursor: pointer;

}

.translation-btn-wrapper .vertical-line {
  width: 0.0625rem;
  height: 1.2rem;
  background-color: #000;
}

.translation-btn-wrapper .tbtn {
  border: none;
  cursor: pointer;

  padding: 0.5313rem;
  border-radius: 1.875rem;
  display: flex;
  box-shadow: 3px 3px 0 #00bfd7;
}

.tbtn h2 {
  color: #feff4f;
}

.tbtn.active {
  display: none;
}

@media screen and (max-width: 1285px) {
  .translation-btn-wrapper {
    display: none;
  }
}
