.integration-header-container {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.squib-integrate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}

.squib-integrate-container > div {
  margin: 0 6rem;
}

.squib-integrate-wrapper {
    width: 27rem;
}

.integration-common-layout__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.integration-layout__header p {
  margin-top: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: "PublicaSans-Regular";
}

.integration-layout__header h2 {
    font-size: 20px;
    font-style: normal;
    color: var(--Black, #2A2A2A);
    font-weight: 400;
    line-height: 30px;
}

.integration-common-layout__container > div {
  margin: 1rem 0;
}

.squib-integrate-wrapper h2 {
  font-family: "PublicaSans-Bold";
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 18rem;
}

.squib-integrate-wrapper p {
  font-size: 1rem;
  font-style: normal;
  ont-weight: 400;
  line-height: 150%;
  margin-top: .5rem;
}

.integration-option-container {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.integration-option-container > div {
  margin: 0 5rem;
}

.squib-integrate-image-wrapper {
    width: 35rem;
    height: 27rem;
    background: var(--Light-Gray, #F4F5FB);
}

.integration-layout__header {
    width: 20rem;
    text-align: center;
}

.integration-common-layout__box {
    width: 210px;
    height: 166px;
    background: var(--Light-Gray, #F4F5FB);
}
