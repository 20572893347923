.members-home-container {
   margin-top: 5rem;
}

.members-home-wrapper {
    display: flex;
}

.benedict-logo {
    width: 10.125rem;
}

.startup-logo {
    width: 6.3125rem;
}

@media (max-width: 960px) {
    .members-home-wrapper {
        flex-direction: column;
    }

    .benedict-member-info {
        margin-top: -.7rem;
    }

}

@media screen and (max-width: 768px) {
    .benedict-member-info {
        margin-top: -.4rem;
    }
}

