.get-in-touch-container {
    display: flex;
    margin-top: 5rem;
  }

  .get-in-touch-container .get-in-touch-wrapper {
      margin: 0 auto;
      text-align: center;
      width: 35rem;
  }

  .get-in-touch-container h1 {
    font-family: PublicaSans-Bold;
    font-weight: 300;
    font-size: 2.5rem;
    letter-spacing: -0.5px;

  }

  .cbtn-wrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .cbtn-wrapper a {
    text-decoration: none;
    z-index: 1;
  }

  .cbtn-getintouch {
    font-size: 22px;
  }

  .btn-get-in-touch {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    background-color: #2A2A2A;
    color: #fff;
  }

  @media screen and (max-width: 1700px) {
    .cbtn-getintouch {
        font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 960px) {
    .get-in-touch-container h1 {
      font-size: 1.8rem;
      line-height: normal;
      letter-spacing: -0.25px;
      padding: 0 4rem;
    }

    .get-in-touch-container .get-in-touch-wrapper .btn-get-in-touch {
      display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
        height: 3rem;
        padding: 0.9rem 1.4rem;
        font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .get-in-touch-functionalities-container {
      margin-top: 2.5rem;
    }

    .get-in-touch-container h1 {
        padding: 0 7rem;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.25px;
    }

    .get-in-touch-container .get-in-touch-wrapper .btn-get-in-touch {
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
        height: 3rem;
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
    }
  }

  @media screen and (max-width: 565px) {
    .get-in-touch-container{
    }
    .get-in-touch-container h1 {
      font-size: 1.3rem;
      padding: 0 5rem;
    }

  }
