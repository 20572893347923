.header-plan-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 8rem;
}

.header-plan-container .cost {
    text-align: center;
    margin: 1rem auto;
    width: 30rem;
}

.header-plan-container .header-plan-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-plan-container .header-plan-wrapper > div {
    margin: 0 .3rem;
}

.header-plan-wrapper .plan-monthly {
  display: flex;
    justify-content: center;
  width: 10rem;
  height: 3.5rem;
  padding: 11.2px 19.2px;
  align-items: center;
  border-radius: 65.6px;
  background: var(--Black, #2A2A2A);
}

.header-plan-wrapper .plan-monthly p {
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: PublicaSans-Bold;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.header-plan-wrapper .plan-yearly {
  display: flex;
  padding: 11.2px 19.2px;
  align-items: center;
}

.header-plan-wrapper .plan-yearly p {
    color: var(--Black, #2A2A2A);
    text-align: center;
    margin-right: 1.5rem;
    leading-trim: both;
    text-edge: cap;
    font-family: PublicaSans-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.header-plan-wrapper .plan-yearly > div {

}

.header-plan-wrapper .plan-yearly .percentoff {
  display: inline-flex;
  height: 2rem;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 51.2px;
  border: 0.8px solid var(--Black, #2A2A2A);
}

.pricing-features-plan-comp-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.pricing-features-plan-layout-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.pricing-features-plan-layout-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 327px;
    height: 774px;
    border-radius: 18px;
    margin: 0 1.2rem;
    background: #FFF;
    box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.05);
}

.item-version-wrapper {
    margin: 0 auto;
}

.item-version-wrapper .item-version{
  display: flex;
  height: 28px;
  width: 4rem;
  margin: 0 auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  background: var(--Light-Gray, #F4F5FB);
}

.pricing-features-plan-layout-wrapper .item-version {
    text-align: center;
    margin-top: 1.5rem;
}

.pricing-features-plan-layout-wrapper .item-price {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: 1.5rem;
}

.pricing-features-plan-layout-wrapper .item-price span {
  color: var(--Black, #2A2A2A);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: PublicaSans-Bold;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1px;
}

.item-options-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2.5rem;
    width: 300px;
}

.item-options-wrapper > div {
    margin: .2rem 0;
}

.item-options-wrapper .item-option-wrapper {
    display: flex;
    align-items: center;
}

.item-option-wrapper .plan-option-wrapper {
    font-family: PublicaSans-Regular;
    font-size: 1rem;
    width: 100%;
    margin: 0 .5rem;
}

.item-option-wrapper .plan-option-wrapper p {
    width: 13rem;
}

.excl-wrapper img {
    margin-right: 1rem;
}

.custom-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.custom-btn {
    font-size: 1rem;
}

.custom-btn-wrapper .posible-access-wrapper {
    margin-top: 1rem;
    font-family: PublicaSans-Regular;
}
