/* items of nav-header  */
.custom-nav-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 30px; */
}
/* This for test purpose */
.flag-wrapper {
  display: none;
  margin-left: 46px;
}

.flagIcon {
  width: 20.526px;
  height: 22.947px;
  flex-shrink: 0;
}

/* This part for styling nav-menu header details */
.vote-card-details {
  /* width: 30.556%; */
  width: 530px;
  margin: 0 auto;
  /* padding-top: 170px; */
}

.vote-card-details .link-options {
  margin-top: 80px;
}

.vote-card-details .choose-voting-options {
  width: 100%;
  margin-top: 30px;
}

.vote-card-details .link-options-title {
  font-family: "PublicaSans-UltraLight";
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.5px;
  font-weight: normal;
}

.vote-card-details .choose-voting-options span,
.vote-card-details .choose-voting-options .link-icons {
  font-size: 1rem;
}

.custom-answer .common-btn {
  /* width: 20%; */
  width: 267px;
  margin: 86px auto 0;
}

.custom-answer .common-btn p {
  /* padding-left: 12px; */
  text-align: center;
}

.custom-translation {
  margin-top: 50px;
}

/* This part is for header nav part view */
@media screen {
  .custom-answer.menu-header-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .custom-answer.nav-menu-mobile-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    min-height: 800px;
  }

  .custom-answer.nav-menu-mobile-cont {
    margin-top: 3.1rem;
  }

  .custom-answer.hamburger-icon-wrapper {
    /* right: 2rem;
    top: 3.8rem; */
    right: 49%;
    top: 61.8rem;
    padding-bottom: 41px;
    position: absolute;
    color: #000;
    z-index: 1000;
  }


  .custom-answer.menu-header-container {
    display: block;
  }

  .custom-answer.open-close-icon {
    width: 2rem;
    height: 2rem;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  .custom-answer.open-close-icon span {
    display: block;
    position: absolute;
    height: 0.15rem;
    width: 90%;
    background: #000;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .custom-answer.open-close-icon span:nth-child(1) {
    top: 0rem;
  }

  .custom-answer.open-close-icon span:nth-child(2) {
    top: 0.625rem;
  }

  .custom-answer.open-close-icon span:nth-child(3) {
    top: 1.25rem;
  }

  .custom-answer.open-close-icon .open:nth-child(1) {
    top: 0.625rem;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .custom-answer.open-close-icon .open:nth-child(2) {
    opacity: 0;
    left: -3.75rem;
  }

  .custom-answer.open-close-icon .open:nth-child(3) {
    top: 0.625rem;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .custom-answer .common-btn {
    /* width: 20%; */
    /* width: 267px;
    margin: 86px auto 0; */
    display: block;
  }
  .custom-translation {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


/* New Footer Style */
.custom-footer {
  /* margin: 315px auto 140px; */
  margin: 100px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.custom-footer .custom-logo-wrapper .logo {
  width: 133.065px;
  height: 47.446px;
  margin-bottom: 5px;
}

.custom-footer .custom-logo-wrapper .logoPart2 {
  width: 116.63px;
  height: 10.369px;
}

.custom-cookie {
  width: 100%;
  font-family: 'PublicaSans-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 12px;
  padding-bottom: 8px;
}

@media only screen and (max-width: 560px) {
  .custom-answer.hamburger-icon-wrapper {
    right: 46%;
    top: 729px;
  }
  .vote-card-details {
    width: 335px;
  }
  .vote-card-details .link-options-title {
    font-size: 15px;
    line-height: 20px;
  }
  .vote-card-details .choose-voting-options span{
    font-size: 13px;
  }
  .custom-answer .common-btn{
    margin: 50px auto 0;
    width: 55%;
  }
  .custom-footer{
    margin: 57px auto 0px auto;
  }
  .custom-footer .custom-logo-wrapper .logo {
    width: 88.71px;
    height: 31.631px;
    margin-bottom: 3.75px;
  }
  
  .custom-footer .custom-logo-wrapper .logoPart2 {
    width: 77.754px;
    height: 6.913px;
  }
}