.functions-model-table-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.option-title {
    display: flex;
    align-items: center;
    width: 30vw;
    padding: 1rem 0;
}

.header-table-text {
  width: 13.75rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4rem;
  margin-top: 2rem;
  text-align: center
}

.option-title p {
  font-family: "PublicaSans-Bold";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2A2A2A;
}

.option-title img {
    margin-left: .8rem;
}

.first-row p {
    width: 15rem;
}

.table-wrapper .first-col .row {
    display: flex;
}

.app-versions {
    width: 20vw;
    display: flex;

}

.lite, .pro, .pro-plus {
    flex: 1;
}

.pro-plus span strong {
    width: 5rem;

}

.lite span, .pro span, .pro-plus span {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.lite span strong, .pro span strong, .pro-plus span strong {
    font-family: "PublicaSans-Bold";
}

.opt-versions-wrapper {
    display: flex;
    flex-direction: column;
    width: 5rem;
}

.pro {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

.check-one span, .check-two span, .check-three span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.check-two {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.check-one, .check-two, .check-three {
  flex: 1;
}

hr {
    border-top: 1px solid #e5e5e5;
}
