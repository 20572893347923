.squib-button {
  display: flex;
}
.squib-button button {
  position: relative;
  padding-left: 38px;
  padding-right: 39px;
  padding-top: 18.5px;
  padding-bottom: 18.5px;
  border-radius: 100px;
  background-color: var(--pink);
  color: var(--yellow);
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PublicaSans-Bold";
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  box-shadow: 4px 8px 0 -2px var(--blue);
  margin-bottom: 6px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 0;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
}
.squib-button2 button {
  position: relative;
  padding: .875rem 1.5rem;
  border-radius: 6.25rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PublicaSans-Bold";
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 0.375rem;
  margin-top: 0;
  margin-right: 0.125rem;
  margin-left: 0;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 4px solid transparent;
}

.squib-button2 button:hover {
  background-color: #F6F6F9;
  border: 4px solid #2A2A2A;
  color: #2A2A2A;
}

.squib-button button:hover {
  box-shadow: 0 0 0 0 var(--blue);
  background-color: var(--blue);
  margin: 4px;
  margin-bottom: 0;
  margin-top: 6px;
  margin-right: 0;
  margin-left: 2px;
}

.squib-button3 button {
  position: relative;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  border-radius: 6.25rem;
  background-color: #2A2A2A;
  height: 3.75rem;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PublicaSans-Bold";
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 0.375rem;
  margin-top: 0;
  margin-right: 0.125rem;
  margin-left: 0;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 4px solid transparent;
}

.squib-button3 button:hover {
  background-color: #F6F6F9;
  border: 4px solid #2A2A2A;
  color: #2A2A2A;
}


.common-btn {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0.7rem 1.2rem;
  letter-spacing: 0.5px;
  border-radius: 4.1rem;
  background: var(--Light-Gray, #F6F6F9);
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.common-btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  padding: 0.875rem 1.5rem;
  background-color: #2A2A2A;
  letter-spacing: 0.5px;
  line-height: 24px;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.common-btn2 p {
  color: #fff;
  font-family: "PublicaSans-Bold";
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.common-btn > * {
  margin: 0 .5rem;
}

.common-btn p {
  font-size: 1rem;
  margin: 0;
  font-family: "PublicaSans-Bold";
  transition: 0.5s ease-in-out;
}

.common-btn:hover {

}

@media screen and (max-width: 1350px) {
  .common-btn {
    padding: 8px 18px;
  }
  .common-btn p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1285px) {
  .common-btn {
    display: none;
  }

  .squib-logo {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 960px) {
  .squib-logo {
    width: 6rem;
  }
}
