.home-cards-comp-container {
  margin-top: 5rem;
}

.home-cards-comp-container h1 {
    text-align: center;
    font-size: 3.75rem;
    font-family: 'PublicaSans-Bold';
}

.home-cards-comp-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 87px;
}

.home-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 25.5rem;
  height: 33.8125rem;
  margin: 0 1.25rem;
  border-radius: 0.625rem;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.16);
}

.text-home-cards-wrapper {
    width: 30rem;
    padding: 0 4rem;
}

.text-home-cards-wrapper p {
    font-size: 2.5rem;
    font-family: 'PublicaSans-Bold';
    text-align: center;
}

.home-cards img {
    width: 22.5rem;
}
