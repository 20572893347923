.app-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.app-header-container p {
    width: 52rem;
}

.voting-app-opt-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.voting-app-opt-container > div {
    margin-bottom: 12rem;
}

.app-layout-container .app-text-wrapper {
    width: 27rem;
}

.app-layout-container .app-text-wrapper h2 {
  font-family: PublicaSans-Regular;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.app-layout-container .app-text-wrapper p {
    font-family: PublicaSans-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2A2A2A;
    margin-top: 1.2rem;
}

.voting-opt-one-container .voting-opt-one-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.voting-opt-one-img-wrapper p {
    color: #FFF;
    font-family: PublicaSans-Regular;
    font-size: 51.123px;
    width: 18.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    left: 10rem;
    bottom: 5rem;
    position: absolute;
}

.voting-opt-one-img-wrapper img {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
}

.voting-opt-one-container .voting-opt-one-wrapper > div {
    margin: 0 5rem;
}

.voting-opt-two-container .voting-opt-two-wrapper {
    display: flex;
    align-items: center;
}

.voting-opt-two-img-wrapper p {
    color: #FFF;
    font-family: PublicaSans-Regular;
    font-size: 51.123px;
    width: 18.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    left: 5rem;
    bottom: 5rem;
    position: absolute;
}

.voting-opt-two-img-wrapper {
    position: relative;
}

.voting-opt-two-img-wrapper img {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
}

.voting-opt-two-container .voting-opt-two-wrapper > div {
    margin: 0 5rem;
}

.voting-opt-three-container .voting-opt-three-wrapper {
    display: flex;
    align-items: center;
}

.voting-opt-three-img-wrapper {
    position: relative;
}

.voting-opt-three-img-wrapper p {
    color: #FFF;
    font-family: PublicaSans-Regular;
    font-size: 51.123px;
    width: 18.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    left: 5rem;
    bottom: 5rem;
    position: absolute;
}

.voting-opt-three-img-wrapper img {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
}

.voting-opt-three-container .voting-opt-three-wrapper > div {
    margin: 0 5rem;
}

.getintouch-app-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.getintouch-app-wrapper p {
  color: var(--Black, #2A2A2A);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Oooh Baby;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.store-app-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.store-app-wrapper > div {
    margin: 0 .3rem;
}
