.startup-event-comp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.startup-event-comp-container > div {
    margin: 0 3rem;
}

.startup-event-comp-wrapper {
    width: 26rem;
    padding: 2rem 0;
}

.startup-img {
  width: 540px;
}

.startup-event-comp-wrapper h2 {
  font-family: 'PublicaSans-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 1rem;
  color: #2A2A2A;
}

.image-cont-paragraph {
    margin-top: 1rem;
    font-family: 'PublicaSans-Regular';
    line-height: 150%;
}

.startup-event-comp-wrapper h3 {
  font-family: 'Oooh Baby';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #2A2A2A;
  margin-top: 1rem;
}

.success-stories-btn {
    width: 100%;
}

.btn-success {
    font-size: 1.25rem;
}

.btn-success {
   width: 29rem;
}

.media-comp-video {
    width: 32rem;
}

.startup-video-box {
   box-shadow: 2px 1px 15px 0px rgba(0,0,0,0.43);
   background: #000;
}

.home-header-style h1 {
    font-family: PublicaSans-Bold;
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: normal;
    color: #2A2A2A;
    letter-spacing: -0.5px;
    text-align: center;
    width: 42rem;
}

.home-getintouch-wrapper h1 {
   width: 35rem;
}

@media (max-width: 1200px) {
  .startup-event-comp-container {
      flex-direction: column;
  }

  .startup-img {
    width: 21.875rem;
  }
}

@media (max-width: 960px) {
    .home-getintouch-wrapper h1 {
        padding: 0 6rem;
        font-size: 1.8rem;
        letter-spacing: -0.25px;
    }

    .home-getintouch-wrapper .cbtn-wrapper .btn-get-in-touch {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
        height: 3rem;
        padding: 0.9rem 1.4rem;
        font-size: 1.2rem;
    }

    .startup-event-comp-container {
        margin-top: 7rem;
    }

    .startup-event-comp-wrapper {
        width: 22rem;
    }

    .startup-event-comp-wrapper p {
        width: 100%;
    }

    .home-get-in-touch-wrapper {
        width: 40rem;
        margin-top: -1rem;
    }

    .home-get-in-touch-wrapper h1 {
       padding: 0 3rem;
    }

    .media-comp-video {
        width: 25rem;
    }
}

@media (max-width: 768px) {
    .home-getintouch-wrapper h1 {
        padding: 0 8rem;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.25px;
    }

    .home-getintouch-wrapper .cbtn-wrapper .btn-get-in-touch {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
        height: 3rem;
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
    }
}

  @media (max-width: 545px) {
  {

  }
  }
