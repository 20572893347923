.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 2rem;
  z-index: 100;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86.25rem;
  height: 6.25rem;
  border-radius: 3.125rem;
  padding: 26px 10px;
  background-color: #fff;
}

.squib-logo-wrapper {
  margin-left: 2.5rem;
  height: auto;
}

.squib-logo-wrapper .logo {
  width: 105px;
}

.create-voting-wrapper {
  margin-right: 40px;
}

.logo-wrapper img {
  display: flex;
  align-items: center;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-menu li {
  list-style: none;
  font-size: 1rem;
  line-height: 22px;
  position: relative;
}

.nav-menu li a {
  margin: 0 1rem;
  text-decoration: none;
  background-color: #F4F5FB;
  padding: 0.9375rem 1.25rem;
  display: flex;
  align-items: center;

  border-radius: 10rem;
  background: transparent;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  font-family: PublicaSans-Bold;
}

.nav-menu li a:hover {
  padding: 0.8375rem 1.25rem;
  border-radius: 10rem;
  background-color: #F4F5FB;
  transition: all 0.3s ease-in-out;
}

.create-voting-wrapper .btn-cb {
  color: #2a2a2a;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  font-family: PublicaSans-Bold;
  background: var(--Light-Gray, #F6F6F9);
  width: 100%;
}

.create-voting-wrapper .btn-cb:hover {
  background: #fff;
}

@media screen and (max-width: 1540px) {
  .nav-wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 1285px) {
  .nav-wrapper {
    display: none;
  }
  .nav-menu, .squib-logo-wrapper {
    display: none;
  }

  .nav-menu-box {
    display: flex;
      justify-content: center;
      align-items: center;
      background-color: gray;
      padding-bottom: 1rem;
      position: sticky;
      top: 0;
  }

  .grid-nav-container {
    display: flex;
    justify-content: center;
  }

  .nav-header-mobile-wrapper {
    display: flex;
  }

  .nav-container .squib-logo-wrapper{
     margin: 0 auto;
  }
}
