.pricing-features-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.pricing-features-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60rem;
}

@media (max-width: 960px) {
    .pricing-features-header-container {
        margin-top: 1rem;
    }

    .pricing-features-header-wrapper {
        width: 100%;
    }
}
