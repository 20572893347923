.success-stories-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.success-stories-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60rem;
}

@media screen and (max-width: 960px) {
    .success-stories-header-container {
        margin-top: 3rem;
    }

    .success-stories-header-wrapper {
        width: 100%;
    }
}
