.logo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.create-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vote-title {
  text-align: center;
}

.choose-vote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14.0625rem;
  position: relative;
}

.choose-vote-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.choose-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15.6875rem;
  margin: 1.375rem;
  height: 27.0625rem;
  border: solid 3px #d6d6d6;
  overflow: hidden;
  border-radius: 1.875rem;
  position: relative;
}

.choose-img {
  width: 100%;
  height: 45vh;
  object-fit: cover;
  z-index: 2;
}

input[type="file"] {
  display: none;
}

.plus-icon-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: 0.0313rem;
  color: #9b9b9b;
  left: 20%;
  width: 60%;
  height: 100%;
  position: absolute;
}

.plus-icon {
  width: 4.8125rem;
  height: 4.8125rem;
}

.choose-btn-wrapper {
  position: absolute;
  bottom: 20px;
  z-index: 2;
}

.choose-btn {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input-wrapper {
  margin-top: 4.0625rem;
}

.question-inp,
.hashtag-inp {
  width: 100%;
  height: 3.75rem;
  border-radius: 0.625rem;
  padding: 0 1rem;
  margin-bottom: 1.25rem;
  background-color: #F4F5FB;
  font-family: PublicaSans-Regular;
  color: #9b9b9b;
  font-size: 0.9375rem;
  letter-spacing: 0.0313rem;
  border: none;
  outline: none;
  text-indent: 2.8rem;
}

input[type="text"] {
  font-family: PublicaSans-Bold;
  color: #2a2a2a;
  letter-spacing: 0.0313rem;
}

.question-wrapper {
  position: relative;
}

.question-icon {
  position: absolute;
  top: 25%;
  left: 1.75rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.hashtag-wrapper {
  position: relative;
}

.hashtag-icon {
  position: absolute;
  top: 25%;
  left: 1.75rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.check-box {
  width: 30px;
  height: 30px;
  background-color: #F4F5FB;
  border-radius: 3px;
  cursor: pointer;
  transition: ease all 0.5s;
  appearance: none;
}

.check-box:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='-2.1 -2.1 20 20' fill='black'  %3e%3cpath d='M12.707 3.293a1 1 0 00-1.414 0L8 6.586 4.707 3.293a1 1 0 00-1.414 1.414L6.586 8l-3.293 3.293a1 1 0 101.414 1.414L8 9.414l3.293 3.293a1 1 0 001.414-1.414L9.414 8l3.293-3.293a1 1 0 000-1.414z'/%3e%3c/svg%3e");
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 74px;
}

.check-text-wrapper {
  display: flex;
  align-items: center;
}

label div a {
  font-size: 1.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.0313rem;
  color: #ff517b;
}

label div p {
  font-size: 1.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.0313rem;
  font-family: PublicaSans-UltraLight;
  color: #2a2a2a;
}

.VS-btn {
  margin-top: 4.5875rem;
}

.hintText {
  font-family: "PublicaSans-UltraLight";
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.0313rem;
  margin-top: 1.125rem;
  color: #2a2a2a;
  text-align: center;
}

.store-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.store {
  display: flex;
  justify-content: center;
  margin-top: 1.5625rem;
  padding-bottom: 10rem;
}

.store-ap,
.store-gp {
  margin: 10px;
  cursor: pointer;
}

.store-ap img,
.store-gp img {
  width: 7.25rem;
  height: 2.375rem;
}

@media screen and (max-width: 950px) {
  .create-container {
  }

  .create-wrapper > div {
    margin-top: 2.5rem;
  }

  .choose-vote-container {
    margin-top: 50px;
  }

  .choose-wrapper {
    width: 10.125rem;
    height: 17.375rem;
    margin: 0.625rem;
  }
  .choose-wrapper .choose-btn {
    width: 3.75rem;
    height: 3.75rem;
  }

  .input-container {
    width: 20.9375rem;
  }

  .question-wrapper input {
    height: 3rem;
    font-size: 12px;
    font-family: PublicaSans-Bold;
    letter-spacing: 0.0313rem;
    color: #2a2a2a;
  }

  .question-wrapper .question-icon {
    font-size: 16px;
  }

  .hashtag-wrapper input {
    height: 3rem;
    font-size: 12px;
    font-family: PublicaSans-Bold;
    letter-spacing: 0.0313rem;
    color: #2a2a2a;
  }

  .hashtag-wrapper .hashtag-icon {
    font-size: 16px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
  }

  .checkbox-wrapper .check-box {
    width: 3rem;
    height: 3rem;
  }
  .check-box:checked {
  }
  .checkbox-wrapper .check-text-wrapper {
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
  }

  label div a {
    font-size: 15px;
    line-height: 1.25rem;
    letter-spacing: 0.0313rem;
    color: #ff517b;
  }

  label div p {
    font-size: 15px;
    line-height: 1.25rem;
    letter-spacing: 0.0313rem;
    font-family: PublicaSans-UltraLight;
    color: #2a2a2a;
  }

  .VS-btn .VS-mob-btn {
    width: 200px;
    height: 48px;
    padding: 0;
    font-size: 15px;
    letter-spacing: 0.0238rem;
    text-align: center;
  }

  .hintText {
    font-size: 15px;
    font-family: "PublicaSans-UltraLight";
    line-height: 1.25rem;
    letter-spacing: 0.0313rem;
    margin-top: 20px;
    color: #2a2a2a;
    text-align: center;
    width: 211px;
  }

  .store {
    margin-top: 1.3125rem;
  }

  .store-container .store img {
    width: 115px;
    height: 36px;
  }
}
