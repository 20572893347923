.votingWrapper {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.votingWrapper.fullscreen {
  width: 97%;
  min-height: calc(100 * var(--vh));
}

/* styles for voting container  */

.voting-container {
  /* padding-top: 4%; */
  width: 100%;
}

/* styles for the tabs */
.tab-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;  
}

.image-btn {
border: none;
background: none;
cursor: url(./images/Hand.svg), auto;
transition: transform .1s ease; 
width: 50%;
padding: 0.6875rem;
}

.image-btn.voted {
  cursor: unset;
}

.scale:active{  
transform: perspective(1px) translateZ(0) scale(.9) ;
border: none;
}  

.black {
background-color: #121212;
border-radius: 23.48px;
}

.tab {
display: flex;
height: 4px;
background-color: rgb(210, 210, 210);
margin: 2.5px;
border-radius: 8px;
}

.tab.active {
background-color: #000;
}

/*  creating carousel layout */
.carousel-container {
display: grid;
grid-template-columns: 1fr 500px 1fr;
justify-content: center;
align-content: center;
text-align: center;
align-self: center;
justify-self: center;
flex: 1;
}

.slide-wraper {
display: flex;
justify-content: center;
align-items: center;
margin: auto;
}
.multi-vote-back-arrow-area {
grid-row: 1;
justify-self: end;
align-self: center;
text-align: center;
z-index: 2;
/* margin-top: 15vh; */
margin-left: 17%;
width: 155px;
justify-self: self-end;
}

.multi-vote-carousel-area {
grid-row: 3/4;
grid-column: 2/3;
grid-row: 1;
text-align: center;

}

.multi-vote-forward-arrow-area {
grid-row: 1;
justify-self: start;
align-self: center;
z-index: 2;
/* margin-top: 15vh; */
margin-right: 17%;
width: 155px;
justify-self: self-start;

}

.arrow-wrapper {
border-radius: 50%;
border: none;
text-align: center;
align-items: center;
background-color: #f4f5fb00;
visibility: hidden;
margin-top: 86%;
}

.VotingManagement .arrow-wrapper {
  margin-top: calc(calc(100% - 170px) * 0.86);
}

.arrow-wrapper.visible {
  visibility: visible;
}

.arrow {
display:flex;
background-image: url('../assets/images/chevron-left.png');
width: 40px;
height: 40px;
color: #2A2A2A;
margin: 0 auto;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
cursor: pointer;
}

.forward-arrow {
background-image: url('../assets/images/chevron-right.png');
}

.slick-slider {
margin: 0 auto;
}

.slick-track {
  display: flex;
  align-items: center;
}

/* This part for  Hashtag and question */
.hashtag {
font-family: "PublicaSans-Regular";
/* font-size: 24px; */
/* font-size: 1.9vw; */
font-size: 1.5em;
color: #2A2A2A;
line-height: 21.6px;
/* letter-spacing: -0.5px; */
}

.votingQuestionConatiner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ;
}

.question {
  text-align: center;
  font-size: 40px;
  font-family: "PublicaSans-Bold";
  font-weight: 500 !important;
  line-height: 120%;
  letter-spacing: -0.5px;
  max-width: 100%;
  line-break: auto;
  overflow-wrap: break-word;
}

.custom-vote-wrapper {
display: flex;
justify-content: center;
width: 100%;
/* margin-top: 3rem; */
/* margin: 0 20px; */
}

/* This part for the vote card */
.card-style {
/* border: none; */
border: 4px solid;
width: 100%;
aspect-ratio: 0.5796766744;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
overflow: hidden;
border-radius: 1.875rem;
position: relative;
}

/* This part is for tic icon */
.tick {
background-size: cover;
position: absolute;
top: 10px;
right: 10px;
height: 30px;
width: 30px;
opacity: 0; 
animation: fadeIn 0.3s linear forwards;
z-index: 3;
display: flex;
justify-content: center;
align-items: center;
background-color: var(--white);
border-radius: 100%;
/* animation-delay: 0.1s; */
}


@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

.cyan-tick {
background-image: url("./images/tick_cyan.svg");
}

.magenta-tick {
background-image: url("./images/tick_magenta.svg");
}

.maxLength {
text-align: center;
font-size: 1.157vw;
font-style: normal;
position: absolute;
top: 10px;
font-family: "PublicaSans-Bold";
border-radius: 23.483px;
background: var(--White, #FFF);
padding: 4px 9px;
}

/* This part for the input field */
.user-input-data {
background-color: #e64a7000;
width: 80%;
max-height: 200px;
text-align: center;
border: none;
outline: none;
line-height: normal;
font-style: normal;
letter-spacing: -0.5px !important;
resize: none;
font-family: "PublicaSans-Bold";
hyphens: auto;
/* margin-top: 43px; */
/* overflow: hidden; */
}

/* Hide scroll bar */
/* -webkit- (Chrome, Safari, newer versions of Opera): */
.user-input-data::-webkit-scrollbar { 
display: none; 
}

.user-input-data::placeholder {
letter-spacing: -0.5px !important;
text-align: center;
font-family: 'PublicaSans-Bold';
margin-top: 43px;
color: var(--placeholder-colour, #999);
font-size: var(--placeholder-size, "24px");
}

textarea:focus::-webkit-input-placeholder {
opacity: 0;
}

textarea:focus::-moz-placeholder {
opacity: 0;
}

textarea:focus:-ms-input-placeholder {
opacity: 0;
}

textarea:focus:-moz-placeholder {
opacity: 0;
}

.user-input-data {
  z-index: 2;
}

/* .index-0 .maxLength, */
.index-0 .user-input-data,
.index-0 .user-input-data::placeholder {
color: var(--placeholder-colour);
caret-color: var(--cursor-colour);
}
.index-0 .user-input-data:disabled {
-webkit-text-fill-color: var(--cursor-colour);
opacity: 1;
}

.index-1 .user-input-data,
.index-1 .user-input-data::placeholder {
color: var(--placeholder-colour);
caret-color: var(--cursor-colour);
}
.index-1 .user-input-data:disabled {
-webkit-text-fill-color: var(--cursor-colour);
opacity: 1;
}

.card-style .voteImage {
width: 100%;
height: 100%;
object-fit: cover;
position: absolute;
z-index: 1;
}

/* style for lottie animation */
.lottie-div {
height: 180px;
width: 180px;
align-self: center;
position: relative;
}

.placeholderText {
  width: 90%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: var(--placeholder-colour);
  caret-color: var(--cursor-colour);
  font-family: "PublicaSans-Bold";
}

/* This part for the thump up button and it's hidden for demo purpose */
/* .vote-button {
border: none;
border-radius: 50%;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius: 50%;
height: 80px;
width: 80px;
cursor: pointer;
transform: perspective(1px) translateZ(0); 
transition-property: transform; 
transition-duration: .1s; 
}

.vote-button-active:active {
transform: scale(.9) ;
transition-duration: .1s;
transition-property: transform;

}

.vote-a {
background-color: transparent;
background-image: url("./images/like-a.svg");

}

.vote-b {
background-color: transparent;
background-image: url("./images/like-b.svg");
}

.vote-button-deactivate {
opacity: 50%;
} */


/* This is hidden for demo purpose */
/* styles for the voting options after voting */
/* .result-text {
font-size: 75px;
font-family: "PublicaSans-Bold";
font-weight: 400;
color: var(--yellow);
display: inline;
line-height: 60px;
letter-spacing: -0.5px;
}

.pink,
.blue {
position: absolute;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(180deg, transparent, 25%, rgba(255, 81, 123, 0), var(--pink)) no-repeat center center fixed;
width: 100%;
height: 100%;
}

.blue {
background: linear-gradient(180deg, transparent, 25%, rgba(255, 81, 123, 0), var(--blue));
} */

/* Check Done */
@media only screen and (max-width: 700px) {
.multi-vote-back-arrow-area,
.multi-vote-forward-arrow-area {
  display: none;
}
}

@media screen and (max-width: 969px) {
  .carousel-container.fullscreen {
    align-content: flex-start;
  }

  .slick-slider {
    margin-top: calc(calc(calc(var(--mobileMargin) - 2rem - 21px) / 2) - 2rem - 10px);
  }
}



                                                                