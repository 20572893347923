.signup-form-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.signup-form-wrapper h1 {
  text-align: center;
  font-family: PublicaSans-Bold;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.75rem;
  letter-spacing: -1px;
}

.signup-form-wrapper form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.3rem;
}

.signup-form-wrapper form input {
    width: 528px;
    height: 60px;
    flex-shrink: 0;
    background: var(--Light-Gray, #F4F5FB);
    border: none;
    border-radius: 8px;
    outline: none;
}

.signup-form-wrapper form > input {
    margin-bottom: 1.5rem;
    text-indent: 1.5rem;
}

.signup-form-wrapper form > input::placeholder {
  color: var(--Dark-Gray, #9B9B9B);
  font-family: PublicaSans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;

}

.signup-checkbox {
    display: flex;
    align-items: center;
}

.signup-checkbox label {
    margin-left: .5rem;
    font-family: PublicaSans-Regular;
    font-size: 1rem;
    color: "#2a2a2a";
}

.signup-checkbox label span {
    color: var(--Magenta, #FF517B);
}

.signup-check-box[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='-2.1 -2.1 20 20' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.707 3.293a1 1 0 00-1.414 0L8 6.586 4.707 3.293a1 1 0 00-1.414 1.414L6.586 8l-3.293 3.293a1 1 0 101.414 1.414L8 9.414l3.293 3.293a1 1 0 001.414-1.414L9.414 8l3.293-3.293a1 1 0 000-1.414z'/%3e%3c/svg%3e");
  }

.signup-checkbox input {
    width: 30px;
    height: 30px;
    background-color: #F4F5FB;
    border-radius: 3px;
    cursor: pointer;
    transition: ease all .5s;
    appearance: none;
}

.create-account-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}

.set-account-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 30px;
   font-size: 1rem;
   color: var(--Black, #2A2A2A);
   text-align: center;
   font-family: PublicaSans-Regular;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.5px;
}

.forgot-password {
  display: flex;
  justify-content: end;
  color: var(--Magenta, #FF517B);
}

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.25rem;
}

.set-account-wrapper span {
    color: var(--Magenta, #FF517B);
    margin-left: .5rem;
}

.thanks-signup-container {
    margin-top: 8rem;
}

.thanks-signup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.thanks-signup-wrapper h1 {
    font-family: PublicaSans-Bold;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.75rem;
    letter-spacing: -1px;
    text-align: center;
}

.thanks-signup-wrapper p {
    font-family: PublicaSans-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -1px;
    text-align: center;
    margin-top: 2.5rem;
}
