.funtionalities-header-container {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
}

.funtionalities-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65rem;
}

.funtionalities-header-wrapper p {
    width: 65rem;
}

@media screen and (max-width: 1075px) {
    .funtionalities-header-wrapper p {
        width: 50rem;
    }
}

@media screen and (max-width: 960px) {
    .funtionalities-header-container {
        margin-top: 1rem;
    }

    .funtionalities-header-wrapper p {
        width: 40rem;
    }
}

@media screen and (max-width: 680px) {
    .funtionalities-header-wrapper p {
        width: 30rem;
    }
}
@media screen and (max-width: 525px) {
    .funtionalities-header-wrapper p {
        width: 22rem;
    }
}
