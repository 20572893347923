.survey-employees-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.line-one-se, .line-three-se, .line-four-se, .line-five-se {
    width: 3.8rem;
    height: 0.1648rem;
    background-color: #D1D5D9;
    margin-top: 1.5rem;
    border-radius: 1rem;
}

.line-two-se {
    width: 3.8rem;
    height: 0.1648rem;
    background-color: #2A2A2A;
    margin-top: 1.5rem;
    border-radius: 1rem;
    margin-left: 1rem;
}

.survey-employees-container .survey-employees-wrapper,
.survey-employees-container .se-img-wrapper {

}

.survey-employees-wrapper {
    display: flex;
    flex-direction: column;
    width: 32rem;
    padding: 1.5rem 0;
}

.survey-employees-wrapper h1 {
  font-family: "PublicaSans-Bold";
  font-weight: 400;
  color: #2a2a2a;
  width: 20rem;
}

.survey-employees-wrapper h1,
.survey-employees-wrapper p {
  margin: 0 1.5rem 1.5rem 5rem;
}

.survey-employees-wrapper p {
  font-family: "PublicaSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #2a2a2a;
  width: 25rem;
}


.mobile-voting-card-two {
   width: 27.5rem;
    height: 52rem;
}

@media (max-width: 1490px) {
  .survey-employees-container {
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
  }

  .survey-employees-container .survey-employees-wrapper {
    padding-bottom: 0rem;
  }
}

@media (max-width: 1200px) {
  .survey-employees-wrapper {
     padding: 1.5rem 1rem;
  }

  .survey-employees-wrapper h1 {
    font-size: 1.25rem;
  }

  .survey-employees-wrapper p {
    font-size: 1rem;
  }

  .survey-employees-container .survey-employees-wrapper,
.survey-employees-container .se-img-wrapper {
  margin: 0;
}
}


@media (max-width: 960px) {
  .survey-employees-wrapper h1, .survey-employees-wrapper p {
    margin: 0 0rem 1.5rem 0rem;
  }

}

@media (max-width: 768px) {
  .survey-employees-container {
    margin-top: 2rem;
  }

  .survey-employees-wrapper {
    align-items: center;
  }

  .survey-employees-wrapper h1 {
    margin-top: -2rem;
  }

  .survey-employees-wrapper p {
    width: 20rem;
    text-align: left;
    margin-top: -1rem;
  }

  .mobile-voting-card-two {
    width: 20.25rem;
    height: 600px;
 }
}

@media (max-width: 480px) {
  .survey-employees-wrapper {

  }
}
