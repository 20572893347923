.faq-container {
    margin: 10rem auto;
}

.faq-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.faq-title {
    font-family: PublicaSans-Bold;
    color: #2a2a2a;
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: -0.5px;
}

.questions-cont {
    margin-top: 5rem;
    .border-top {
        width: 100%;
        border-top: 1px solid #ccc;
    }
}

.questions-wrapper {
    width: 58rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    padding: 1.75rem 0;
}
.faq-question {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questions-icon-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question h3 {
    padding-right: 2rem;
    flex: 1;
    margin: 0;
    font-family: PublicaSans-Bold;
    font-size: 1.875rem;
    font-weight: 400;
    color: #2a2a2a;
}

 .questions-icon-wrapper img {
    width: 3.75rem;
    height: 3.75rem;
    margin-right: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease; 
}

img.arrow-down {
    transform: rotate(0deg);
}

img.arrow-up {
    transform: rotate(180deg);
}

.faq-answer {
    width: 90%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

.faq-answer.show {
    max-height: 10rem; 
    margin-top: .2rem;
    padding-top: 0;
    padding-bottom: 1rem;
    opacity: 1;
}

.faq-answer p {
    margin: 0;
    padding: 1rem 0;
    padding-right: 2rem;
    font-family: PublicaSans-Regular;
    font-size: 1.25rem;
    font-weight: 400;
    color: #2a2a2a;
    line-height: 150%;
  }

.faq-answer.show p {
    opacity: 1; 
}

@media (max-width: 960px) {
    .faq-container {
        width: 90%;
        margin: 5rem auto;
    }

    .questions-cont {
       width: 100%;
       margin-top: 3rem;
    }

    .faq-title {
        font-size: 1.8rem;
    }

    .questions-wrapper {
        width: 100%;
    }

    .faq-question {
        width: 90%;
    }

    .faq-question h3 {
        font-size: 1.5rem;
    }

    .faq-answer {
        width: 90%;
    }

    .questions-icon-wrapper img {
        width: 2.6rem;
        height: 2.6rem;
    }

    .faq-answer p {
        font-size: 1.20rem;
    }
}

@media (max-width: 768px) {
    .faq-container {
        width: 90%;
        margin: 4rem auto;
    }

    .questions-cont {
       margin-top: 1.5rem;
    }

    .faq-title {
        font-size: 1.5rem;
        text-align: center;
    
    }

    .questions-wrapper {
        width: 100%;
    }

    .faq-question {
        width: 90%;
    }

    .faq-question h3 {
        font-size: 1.25rem;
    }

    .faq-answer {
        width: 90%;
    
    }

    .questions-icon-wrapper img {
        width: 2.5rem;
        height: 2.5rem;
    }

    .faq-answer p {
        font-size: 1rem;
    }
}



