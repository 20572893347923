.waitinglist-comp-container {
    width: 100%;
  }

.waitinglist-comp-wrapper h2 {
  width: 52rem;
  font-family: 'PublicaSans-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: normal;
  letter-spacing: -.5px;
  text-align: center;
  color: #2a2a2a;
}

.waitinglist-comp-wrapper .waitinglist-desc {
  font-family: PublicaSans-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  text-align: center;
  margin-top: 2rem;
  color: #2a2a2a;
}

.waitinglist-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.5rem;
}

.waitinglist-content-wrapper {
  display: flex;
  position: relative;
}

.waitinglist-content-wrapper .error-message {
  position: absolute;
  top: 2.8rem;
  left: 0;
  color: #ff0000;
  font-size: 1rem;
  font-family: PublicaSans-Regular;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 1rem;
}

.waitinglist-wrapper input {
    width: 27rem;
    height: 3.75rem;
    border-radius: .75rem;
    border: none;
    outline: none;
    padding: 16px 24px;
    margin-right: 1rem;
    background-color: #f6f6f9;
}

.waitinglist-wrapper input::placeholder {
    font-family: PublicaSans-Regular;
    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: #636467;
}

.waitinglist-wrapper input[type="text"] {
  font-family: PublicaSans-Regular;
    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    color: #2a2a2a;
}

.btn-app-versions {
  font-size: 1.375rem;
}

.waitinglist-btn {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;

}

.waitinglist-subscribe-text {
  font-family: PublicaSans-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #fff;
  margin-top: 1.5rem;
}

.msg-modal-waitinglist-wrapper {
  position: relative;
}

.msg-modal-waitinglist-wrapper .modal-msg-waitinglist-wrapper h1 {
  font-family: PublicaSans-Bold;
  font-size: 1.875rem;
  color: #2a2a2a;
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

.msg-modal-waitinglist-wrapper p {
  text-align: center;
  font-family: PublicaSans-Regular;
  font-size: 1.25rem;
  color: #2a2a2a;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
}

.msg-modal-waitinglist-wrapper .btn-form-modal-waitinglist-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.btn-form-modal-waitinglist-wrapper .btn-form-waitinglist-modal {
  font-family: PublicaSans-Bold;
  font-size: 1.25rem;
  letter-spacing: .0313rem;
  height: 3.75rem;
  padding: 0.875rem 1.5rem;
  background-color: #2a2a2a;
  color: #fff;
  border: 3.5px solid transparent;
  outline: none;
  cursor: pointer;
  transition: ease all .5s;
}

.btn-form-modal-waitinglist-wrapper .btn-form-waitinglist-modal:hover {
  background-color: #fff;
  color: #2a2a2a;
  border: 3.5px solid #2a2a2a;
}

.close-icon-waitinglist-wrapper {
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
}

.close-icon-waitinglist-wrapper img {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 1700px) {
  .btn-form-waitinglist-modal{
    display: block;
  }
  .waitinglist-comp-wrapper h1 {
      font-size: 3rem;
      text-align: center;
  }
  .btn-app-versions {
      font-size: 1.1rem;
  }
}

@media screen and (max-width: 1300px) {
  .waitinglist-comp-wrapper h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      transition: all 0.3s ease-in-out;
  }

  .waitinglist-comp-wrapper .waitinglist-desc {
      transition: all 0.3s ease-in-out;
 }

 form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

 form .waitinglist-subscribe-text {
    font-size: 1.25rem;
    font-style: normal;
    text-align: center;
    line-height: 150%;
  }
}

@media screen and (max-width: 960px) {
  .waitinglist-comp-wrapper h2 {
      font-size: 2rem;
      line-height: 3rem;
      transition: all 0.3s ease-in-out;
      line-height: normal;
      letter-spacing: -0.5px;
      width: 40rem;
  }

  .waitinglist-comp-wrapper .waitinglist-desc {
      width: 38rem;
      transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 768px) {
  .waitinglist-content-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

 .waitinglist-comp-wrapper h2 {
      width: 70vw;
      font-size: 1.5rem;
      line-height: normal;
      letter-spacing: -0.25px;
  }

  .waitinglist-comp-wrapper .waitinglist-desc {
      font-size: 1rem;
      width: 15rem;
  }

  form .waitinglist-subscribe-text {
    font-size: 1rem;
    width: 28rem;
  }

  .waitinglist-wrapper {
    display: flex;
    flex-direction: column;
  }

  .waitinglist-wrapper input {
    width: 26rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .waitinglist-wrapper .error-message {
    top: 2.5rem;
    font-size: .9rem;
  }

  .waitinglist-wrapper .waitinglist-btn {
    margin-top: .3rem;
  }
}

@media screen and (max-width: 490px) {
  .waitinglist-comp-wrapper h2 {
    font-size: 1.5rem;
    line-height: normal;
    letter-spacing: -0.25px;
}

  .waitinglist-comp-wrapper .waitinglist-desc {
      font-size: 1rem;
      margin-bottom: .5rem;
      width: 20rem;
  }

  form .waitinglist-subscribe-text {
    font-size: 1rem;
    width: 24rem;
  }

  .waitinglist-wrapper input {
    width: 23rem;
    margin-bottom: 1rem;
  }

  .waitinglist-wrapper .waitinglist-btn {
    margin-top: .1rem;
    font-size: 1rem;
    height: 3rem;
    width: 11.1rem;
  }
}

@media screen and (max-width: 400px) {
  .waitinglist-comp-wrapper h2 {
    font-size: 1.25rem;
    line-height: normal;
    letter-spacing: -0.25px;
    width: 18rem;
  }

  .waitinglist-comp-wrapper .waitinglist-desc {
      font-size: 1rem;
      margin-bottom: .5rem;
  }
}
