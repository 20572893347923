.voting-result {
    position: absolute;
    top: 12px;
    left: 13px;
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    padding: 0 3%;
    aspect-ratio: 2.5;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.voting-result span {
    font-size: 1.5em;
    margin-bottom: 1px;
    flex: 1;
    font-family: PublicaSans-Bold;
}