.comparison-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.comparison-header-container p {
    width: 52rem;
}

.comparison-layout-container {
    display: flex;
}

.comparison-layout-container .comparison-layout-wrapper {
    width: 25rem;
    text-align: center;
}

.comparison-layout-wrapper {
    margin: 0 2.2rem;
}

.comparison-layout-wrapper p:nth-child(1) {
  color: var(--Black, #2A2A2A);
  text-align: center;
  font-family: PublicaSans-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.comparison-layout-wrapper p:nth-child(2) {
  margin-top: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%
}

.comparison-areas-container h2 {
  margin: 0 auto;
  text-align: center;
  margin-top: 8rem;
  font-size: 2.5rem;
  width: 55.75rem;
  font-weight: 400;
  font-family: PublicaSans-Bold;
}

.areas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
}

.market-env-position-container {
  margin-top: 10rem;
}

.market-env-position-container h1 {
  text-align: center;
}

.market-env-position-container .market-env-position-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}

.market-env-position-wrapper > div {
    margin: 0 5rem;
}

.market-env-position-content {
    width: 22rem;
}

.market-env-position-content h2 {
    width: 18rem;
}

.market-env-position-content p {
    font-family: PublicaSans-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 1.5rem;
}

.scientific-nature-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49rem;
    height: 42rem;
    border-radius: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
    position: relative;
}

.scientific-nature-wrapper .scientific-nature-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--Dark-Gray, #9B9B9B);
    top: 5rem;
    position: absolute;
}

.scientific-nature-wrapper .spass-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--Dark-Gray, #9B9B9B);
    right: 4.1rem;
    top: 19.1rem;
    position: absolute;
}

.scientific-nature-content {
    display: flex;
    justify-content: center;
    width: 32rem;
    height: 28rem;
    position: relative;
}

.scientific-nature-content .horizontal-line {
    border-style: dashed;
    border-width: 1px;
    border-color: red;
    height: 100%;
}

.scientific-nature-content .vertical-line {
    border-style: dashed;
    border-width: 1px;
    border-color: red;
    width: 100%;
}

.market-box-one {
    width: 16rem;
    height: 13.2rem;
    position: absolute;
    top: 0;
    left: 0;
}

.market-box-two {
    width: 16rem;
    height: 13.2rem;
    position: absolute;
    top: 0;
    right: 0;
}

.market-box-three {
    width: 16rem;
    height: 14.7rem;
    position: absolute;
    bottom: 0;
    left: 0;
}

.market-box-four {
    width: 16rem;
    height: 14.7rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.market-point {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #000;
}

.first-position {
    top: 2rem;
    left: 1rem;
}

.second-position {
    top: 8rem;
    right: 4rem;
}

.third-position {
    bottom: 3rem;
    left: 3rem;
}

.fourth-position {
    top: 1rem;
    right: 3rem;
}

.fifth-position {
    top: 1rem;
    left: 2rem;
}

.sixth-position {
    bottom: 4rem;
    right: 1rem;
}

.seventh-position {
    bottom: 1rem;
    left: -.5rem;
}

.eighth-position {
    top: 1rem;
    left: 4rem;
}

.ninth-position {
    top: 7.2rem;
    right: 3rem;
}

.area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.area p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: PublicaSans-Regular;
}

.getintouch-comparison-container {
    margin-top: 10rem;
}
