.home-container {
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
  }

  .home-container h1 {
    margin: 0 auto;
    width: 40rem;
    font-size: 6.25rem;
    line-height: 6.25rem;
    letter-spacing: -2px;
    font-weight: 400;

  }

  .home-container .home-wrapper .second-title {
    font-family: PublicaSans-Bold;
    text-align: center;
    margin-top: -.9rem;
    font-size: 6.25rem;
    line-height: 6.25rem;
    letter-spacing: -2px;
    font-weight: 400;
  }

  .home-wrapper p {
    width: 59rem;
    font-size: 2.5rem;
    line-height: normal;
    margin-top: 2.5rem;
  }

  .home-video-wrapper {
    margin-top: 10rem;
    border-radius: 2rem;
    overflow: hidden;
  }

  .home-video-wrapper .home-video {
    width: 1380px;
    height: 777px;
    border: none;
    border-radius: 2rem;
    object-fit: cover;
  }

  .voting-home-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 9rem;
    padding: 6.5rem 0rem 6.5rem 0rem;
    background-color: #ffffff;
    border-radius: 2.25rem;
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
  }

  .voting-home-subheader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.8rem;
  }

  .voting-options-responsive-container {
    display: none;
  }

  .voting-options-responsive-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .voting-options-responsive-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 47.5%;
    padding: 1.5rem 0 1.5rem 0;
  }

  .voting-options-responsive-A p {
    font-family: 'Oooh Baby';
    font-style: normal;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1.875rem;
    margin-top: .8rem;
    color: #2A2A2A;
  }

  .voting-options-responsive-B {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 47.5%;
  }

  .voting-options-responsive-B p {
    font-family: 'Oooh Baby';
    font-style: normal;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1.875rem;
    margin-top: .8rem;
    color: #2A2A2A;
  }

  .voting-home-header-wrapper {
    margin-bottom: 2rem;
  }

  .voting-home-header-wrapper p {
    font-family: 'PublicaSans-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3.75rem;
    color: #2A2A2A;
  }

  .voting-home-wrapper .vote-for-a,
  .voting-home-wrapper .vote-for-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    padding: 0 1rem;
    position: relative;
  }

  .voting-home-wrapper .arrow-a {
    position: absolute;
    top: 2.9rem;
    left: 11.5rem;
  }

  .voting-home-wrapper .arrow-b {
    position: absolute;
    top: 2.9rem;
    right: 11.5rem;
  }

  .voting-home-wrapper .vote-cta p {
    font-family: 'Oooh Baby';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.875rem;
    text-align: center;
    width: 320px;
  }

  .voting-home-wrapper .vote > div {
    margin-top: 1rem;
  }


  .home-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70rem;
    margin-top: 2.8rem;
  }

  .home-text {
    width: 40rem;
    font-family: 'PublicaSans-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25rem;
    line-height: 6.25rem;
    letter-spacing: -2px;
    text-align: center;
  }

  .home-subtext {
    font-family: "PublicaSans-Ultralight";
    font-style: normal;
    font-weight: 250;
    font-size: 40px;
    line-height: normal;
    text-align: center;
    color: #2A2A2A;
    margin-top: 3rem;
  }

  .chevron-up-icon-wrapper {
    display: none;
  }

  @media screen and (max-width: 1285px) {
    .home-container h1, .home-container .home-wrapper .second-title  {
      font-size: 5rem;
      line-height: 5rem;
    }



    .home-container p {
      font-size: 1.875rem;
      line-height: normal;
    }

    .home-video-wrapper {
      width: 100%;
      border-radius: 0;
    }
    .home-video-wrapper .home-video {
      width: 100%;
      height: 32rem;
      border-radius: 0;
      object-fit: cover;
    }

    .voting-home-wrapper {
       width: 100%;
       justify-content: center;
        align-items: center;
        align-content: center;
       border-radius: 0;
    }

    .voting-home-wrapper .vote-cta {
      display: none;
    }

    .voting-options-responsive-container {
      display: block;
    }

    .voting-options-wrapper {
      width: 100%;
      margin-left: 2.5rem;
    }

    .voting-home-wrapper {
      padding: 8rem;
    }

    .chevron-up-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8rem;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: #F4F5FB;
      overflow: hidden;
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    .home-video-wrapper {
      width: 100%;
    }
    .home-video-wrapper .home-video {
      width: 100%;
      height: 30rem;
      border-radius: 0;
      object-fit: cover;
    }
  }

  @media (max-width: 960px) {
    .home-container {
      margin-top: 4.5rem;
    }

    .home-container h1, .home-container .home-wrapper .second-title  {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }

    .home-video-wrapper {
      width: 100%;
      bo¡rder-radius: 0;
    }
    .home-video-wrapper .home-video {
      width: 100%;
      height: 28rem;
      object-fit: cover;
      border-radius: 0;
    }

    .home-container p {
      font-size: 1.5rem;
      line-height: normal;
      width: 40rem;
      margin: 1.5rem auto;
    }

    .home-header-wrapper {
      width: 80vw;
    }

    .home-text {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }

    .home-subtext {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .voting-home-header-wrapper p {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    .voting-options-responsive-A p {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .voting-options-responsive-B p {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

  }

  @media (max-width: 768px) {
    .home-container {
      margin-top: 4.5rem;
    }
    .home-container h1, .home-container .home-wrapper .second-title  {
      font-size: 2.25rem;
      line-height: 2.25rem;
      letter-spacing: -.5px;
      width: 22rem;
    }

    .home-video-wrapper {
      margin-top: 3rem;
      width: 100%;
    }

    .home-video-wrapper .home-video {
      width: 100%;
      height: 25rem;
      object-fit: cover;
    }

    .home-container p {
      font-size: 1.25rem;
      line-height: normal;
      text-align: center;
      width: 22rem;
      margin: 1.5rem auto;
    }

    .img-home-header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    .img-home-header-wrapper .char-one {
      width: 8rem;
      transition: all 0.5s ease-in-out;
    }

    .img-home-header-wrapper .char-two {
      width: 9.3rem;
      transition: all 0.5s ease-in-out;
    }

    .voting-home-wrapper {
      flex-direction: column;
      margin-top: 6rem;
      height: 38rem;
      padding: 0;
      border-radius: 0;
      box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
    }

    .voting-home-header-wrapper {
      margin-top: 2rem;
    }

    .voting-home-header-wrapper p {
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }

  }

  @media (max-width: 430px) {
    .home-container {
      margin-top: 4.5rem;
    }

    .home-container p {
      margin-top: -1rem;
      width: 18rem;
    }
  }
