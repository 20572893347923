.group-one-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width : 100%;
    margin-top: 6rem;
}

.groups-wrapper {
    margin-top: 9rem;
}

.group-item-one-wrapper {
    display: flex;
}


.content-group-one {
    display: flex;
    flex-direction: column;
}

.group-one-header {
    display: flex;
    flex-direction: column;
}

.group-item-one p:first-child {
    font-family: 'PublicaSans-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: #2A2A2A;
    width: 32rem;
}

.group-item-one p {
    font-family: 'PublicaSans-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 150%;
    margin-top: 1.25rem;
    color: #2A2A2A;
    width: 30rem;
}

.img-group-one-wrapper {
    margin-left: 3rem;
    margin-top: 3.5rem;
}

.group-item-two {
    display: flex;
    flex-direction: column;
    width: 55rem;
    margin-top: 10rem;
}

.content-group-two {
    margin-left: 5rem;
}

.group-item-two-wrapper {
    display: flex;
}

.img-group-two-wrapper {
    margin-right: 2rem;
    margin-top: 3.5rem;
}

.group-item-two p:first-child {
    width: 28rem;
    font-family: 'PublicaSans-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: #2A2A2A;
}

.group-item-two p {
    font-family: 'PublicaSans-Regular';
    font-style: normal;
    font-weight: normal;
    margin-top: 1.25rem;
    font-size: 1.25rem;
    line-height: 150%;
    color: #2A2A2A;
    width: 32rem;
}

.btn-cb-wrapper {
    margin-top: 3rem;
}

.btn-cb {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  background-color: #fff;
}

@media (max-width: 1700px) {
    .btn-cb {
        font-size: 1.1rem;
    }

    .group-item-one p:first-child {
        font-size: 2.5rem;
        margin: 0;
    }

    .group-item-two p:first-child {
        font-size: 2.5rem;
        margin: 0;
    }

}

@media (max-width: 1300px) {
    .group-item-one-wrapper {
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .group-item-two-wrapper {
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 2.5rem;
    }

    .img-group-one-wrapper {
        position: absolute;
        top: -8rem;
        right: 10rem;
    }

    .img-group-one-wrapper img {
        width: 10rem;
        transition: all 0.5s ease-in-out;
    }

    .group-item-one p:first-child {
        width: 23rem;
        font-size: 2rem;
        margin: 0;
    }

    .content-group-one, .content-group-two {
        margin: 0;
    }

    .group-item-two {
        margin-top: 5rem;
    }

    .img-group-two-wrapper {
        position: absolute;
        top: -8rem;
        right: 10rem;
        transform: scaleX(-1);
    }

    .img-group-two-wrapper img {
        width: 12rem;
        transition: all 0.5s ease-in-out;
    }

    .group-item-two p:first-child {
        width: 20rem;
        font-size: 2rem;
        margin: 0;
    }
}

@media (max-width: 1024px) {
    .group-one-container {
        margin-top: 5rem;
    }

    .content-group-one  {
        margin-right: 0;
    }

    .content-group-two {
        margin-left: 0;
    }

    .group-item-one p:first-child {
        width: 21rem;
        font-size: 1.8rem;
        margin: 0;
        letter-spacing: -0.25px;
    }

    .group-item-two {
        margin-top: 3rem;
    }

    .group-item-two p:first-child {
        width: 21rem;
        font-size: 2rem;
        margin: 0;
        letter-spacing: -0.25px;
    }

}

@media (max-width: 960px) {
    .common-btn2 {
        display: none;
    }

    .groups-wrapper {
        margin-top: 0rem;
    }

    .content-group-one p:first-child {
        width: 16rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin: 0;
        letter-spacing: -0.25px;
    }

    .img-group-one-wrapper {
        position: absolute;
        top: -5rem;
        right: 14rem;
    }

    .img-group-two-wrapper {
        position: absolute;
        top: -7.5rem;
        right: 13rem;
    }

    .content-group-one p {
        width: 25rem;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: left;
    }

    .group-item-two p:first-child {
        width: 16rem;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 0;
        letter-spacing: -0.25px;
    }

    .content-group-two p {
        width: 25rem;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: left;
    }

    .img-group-one-wrapper img {
        width: 8rem;
        transition: all 0.5s ease-in-out;
    }

    .img-group-two-wrapper img {
        width: 9rem;
        transition: all 0.5s ease-in-out;
    }
}

@media (max-width: 768px) {
    .group-item-one-wrapper {
        margin-top: 3rem;
    }

    .group-item-one p:first-child {
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin: 0;
        line-height: 120%;
        width: 10rem;
        font-weight: 400;
    }

    .group-item-one p {
        width: 20rem;
    }

    .group-item-two p:first-child {
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin: 0;
        line-height: 120%;
        width: 12rem;
        font-weight: 400;
    }

    .group-item-two p {
        width: 20rem;
    }

    .img-group-one-wrapper {
        position: absolute;
        top: -5rem;
        right: 18rem;
    }

    .img-group-two-wrapper {
        position: absolute;
        top: -7rem;
        right: 15rem;
    }

    .img-group-two-wrapper .image-up-two {
        position: absolute;
        top: -7rem;
        right: 15rem;
    }

    .img-group-one-wrapper img {
        width: 7rem;
        transition: all 0.5s ease-in-out;
    }

    .img-group-two-wrapper img {
        width: 8rem;
        transition: all 0.5s ease-in-out;
    }

    .btn-cb-wrapper {
        margin-top: 1.6rem;
        justify-content: center;
        align-items: center;
    }

    .btn-cb-wrapper .btn-cb {
        font-size: 1rem;
        padding: .9rem 1.3rem;
    }
}

@media (max-width: 426px) {
    .group-item-one p:first-child {
        width: 45vw;
    }
    .group-item-two p:first-child {
        width: 45vw;
    }

    .content-group-one p {
        width: 80vw;
    }

    .content-group-two p {
        width: 80vw;
    }

    .img-group-one-wrapper {
        top: -5rem;
    }

    .img-group-two-wrapper {
        top: -5rem;
        margin-right: 3.6rem;
    }

    .img-group-one-wrapper img {
        width: 7rem;
        transition: all 0.5s ease-in-out;
        margin-right: 1rem;
    }
    .img-group-two-wrapper img {
        width: 8rem;
        transition: all 0.5s ease-in-out;

    }
    .btn-cb {
        font-size: 1rem;
    }

    .group-item-two p:first-child {
        font-size: 1.5rem;
        line-height: 2.2rem;
    }
}

@media (max-width: 375px) {
    .btn-cb {
        font-size: 1rem;
    }

    .group-item-one p:first-child {
        font-size: 1.3rem;
        line-height: normal;
    }

    .group-item-two p:first-child {
        font-size: 1.3rem;
        line-height: normal;
    }

    .img-group-two-wrapper {
        top: -6rem;
    }
}
