.imprintPrivacy-comp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.address-info-wrapper .header-contact-info {
  font-family: PublicaSans-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2a2a2a;
}

.imprint-header-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.address-info-wrapper {
  margin: 7rem 28.2rem 0rem 0rem;
  width: 444px;
  padding: 60px;
  border-radius: 18px;
  background-color: #fff;
}

.imprintPrivacy-main-wrapper {
  margin-top: 3rem;
}

.imprintPrivacy-contact-info {
  margin-top: 2rem;
}

.imprintPrivacy-address-text {
  font-family: PublicaSans-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #2a2a2a;
}

.imprintPrivacy-address-text p:nth-child(7) {
  margin-top: 1rem;
  font-family: PublicaSans-Bold;
}

.imprintPrivacy-address-text p:nth-child(8) {
  margin-top: 1rem;
}

.data-rights-header-content {
  font-family: PublicaSans-Bold;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2a2a2a;
}

.header-rights-title {
  font-family: PublicaSans-Bold;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2a2a2a;
}

.first-paragraph {
  margin-top: 1rem;
}

.first-points-wrapper {
  margin: 1rem;
}

.first-points-wrapper p {
  margin-bottom: 0.5rem;
  line-height: normal;
}

.content3-list-wrapper {
  margin: 1rem;
}

.content3-list-wrapper li {
  margin-bottom: 0.5rem;
  line-height: normal;
}

.second-points-wrapper {
  margin-top: 0.3rem;
}

.second-points-wrapper p {
  margin-bottom: 0.5rem;
  line-height: normal;
}

 {
  /* Privacy and Policy */
}
.privacy-policy-wrapper {
  width: 57rem;
}

.privacy-policy-wrapper .first-block {
  .text-2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
  .text-3,
  .text-4 {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .second-block {
  .text-5 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
    letter-spacing: -0.5px;
  }
  ul {
    list-style-type: none;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 2rem;

    li {
      color: var(--Dark-Gray, #636467);
    }
  }
}

.privacy-policy-wrapper .third-block {
  .text-7 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
    letter-spacing: -0.5px;
  }
  .text-8,
  .text-9 {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .fourth-block {
  .text-10 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
    letter-spacing: -0.5px;
  }
  .text-11 {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    margin-left: 1.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .fifth-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
  }

  h3 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    color: #2a2a2a;
  }

  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }

  ul {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 2rem;
    margin-left: 1.5rem;
  }
}

.privacy-policy-wrapper .sixth-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2a2a2a;
  }
  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .seventh-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2a2a2a;
  }
  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .eighth-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2a2a2a;
  }
  h3 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    color: #2a2a2a;
  }

  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

.privacy-policy-wrapper .ninth-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2a2a2a;
  }
  h3 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    color: #2a2a2a;
  }

  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  ul {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 2rem;
    margin-left: 1.5rem;
  }

  .paar-a,
  .paar-b,
  .paar-c {
    p:nth-child(1) {
      font-family: PublicaSans-Bold;
      color: #2a2a2a;
    }
    p:nth-child(2) {
      margin: 0;
    }
  }
}

.privacy-policy-wrapper .tenth-block {
  h2 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2a2a2a;
  }
  h3 {
    font-family: PublicaSans-Bold;
    margin-top: 2rem;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    color: #2a2a2a;
  }

  p {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }

  ul {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 2rem;
    margin-left: 1.5rem;
  }

  .text-73,
  .text-77,
  .text-79,
  .text-81,
  .text-85,
  .text-89,
  .text-91,
  .text-93,
  .text-95,
  .text-99,
  .text-101,
  .text-103,
  .text-105,
  .text-113,
  .text-115,
  .text-117,
  .text-119,
  .text-121,
  .text-125,
  .text-129,
  .text-131,
  .text-135,
  .text-139,
  .text-141,
  .text-145 {
    font-family: PublicaSans-Bold;
    font-size: 20px;
    font-weight: 400;
    color: #2a2a2a;
  }
}

 {
  /* Europe Union Data */
}
.europe-union-data-container {
  margin-top: 2rem;
}

.thirtieth-block {
  width: 57rem;
  .text-70 {
    font-family: PublicaSans-Bold;
    margin-top: 1.5625rem;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #2a2a2a;
  }
  .text-71 {
    font-family: PublicaSans-Regular;
    margin-top: 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2a2a2a;
  }
}

@media (max-width: 1285px) {
}

@media (max-width: 1024px) {
  .imprintPrivacy-comp-container .address-info-wrapper {
    margin: 7rem 30rem 0rem 0rem;
    transition: all 0.3s ease-in-out;
  }

  .europe-union-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .europe-union-data-wrapper {
    padding: 0 3rem;
    transition: all 0.3s ease-in-out;
  }

  .privacy-policy-wrapper,
  .thirtieth-block {
    margin: 0 auto;
    width: 50rem;
  }
}

@media (max-width: 960px) {
  .imprintPrivacy-comp-container {
    margin: 0 auto;
    width: 50%;
  }
  .imprintPrivacy-comp-container .address-info-wrapper {
    margin: 7rem 6rem 0rem 0rem;
  }

  .get-in-touch-container {
    margin-top: 1.5rem;
  }

  .imprintPrivacy-header-wrapper {
    margin-top: 5rem;
    font-size: 1rem;
  }

  .privacy-policy-wrapper,
  .thirtieth-block {
    width: 40rem;
  }
}

@media (max-width: 768px) {
  .imprintPrivacy-comp-container .address-info-wrapper {
    margin: 4rem 4rem 0rem 0rem;
    width: 19.6875rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: red;
  }

  .privacy-policy-wrapper {
    .second-block h2.text-5,
    .third-block h2.text-7,
    .fourth-block h2.text-10, 
    .fifth-block h2.text-12,
    .sixth-block h2.text-23,
    .seventh-block h2.text-25,
    .eighth-block h2.text-27,
    .ninth-block h2.text-48,
    .tenth-block h2.text-70
     {
      font-size: 1.5rem;
      font-family: PublicaSans-Bold;
    }
  }

  .privacy-policy-wrapper {
    .fifth-block {
      h3.text-13, 
      h3.text-15,
      h3.text-19,
      h3.text-21
       {
        font-size: 1.25rem;
        font-family: PublicaSans-Bold;
      }
    }
  }

  .privacy-policy-wrapper {
    .eighth-block {
      h3.text-28, 
      h3.text-34,
      h3.text-37,
      h3.text-40,
      h3.text-42,
      h3.text-46
       {
        font-size: 1.25rem;
        font-family: PublicaSans-Bold;
      }
    }
  }
  
  .privacy-policy-wrapper {
    .ninth-block {
      h3.text-49, 
      h3.text-54,
      h3.text-60,
      h3.text-67
       {
        font-size: 1.25rem;
        font-family: PublicaSans-Bold;
      }
    }
  }
  
  .privacy-policy-wrapper {
    .tenth-block {
      h3.text-71, 
      h3.text-83,
      h3.text-97,
      h3.text-111,
      h3.text-123,
      h3.text-133,
      h3.text-143
       {
        font-size: 1.25rem;
        font-family: PublicaSans-Bold;
      }
    }
  }
  

  .imprintPrivacy-header-wrapper {
    margin-top: 1rem;
  }

  .europe-union-data-wrapper {
    width: 30rem;
  }

  .privacy-policy-wrapper {
    width: 23rem;
    .first-block {
      .text-1 {
        font-size: 1.5rem;
      }
    }
  }

  .thirtieth-block {
    width: 23rem;
    .text-70 {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 450px) {
  .imprintPrivacy-comp-container {
    
  }
  .imprintPrivacy-comp-container .address-info-wrapper {
    margin-left: 4rem;
  }

  .europe-union-data-wrapper {
    width: 28rem;
  }

  .europe-union-data-wrapper h2 {
    font-size: 1.3rem;
  }

  .get-in-touch-impprint-container {
    width: 25rem;
  }
}
