.security-header-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
  }

  .security-integrate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
  }

  .handle-privacy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
  }

  .security-integrate-container > div {
    margin: 0 6rem;
  }

  .handle-privacy-container > div {
    margin: 0 6rem;
  }

  .security-integrate-wrapper {
      width: 27rem;
  }

  .handle-privacy-wrapper {
      width: 27rem;
  }

  .handle-privacy-wrapper p {
    font-size: 16px;
    font-style: normal;
    font-family: PublicaSans-Regular;
    font-weight: 400;
    line-height: 150%;
    margin-top: 1rem;
  }

  .security-common-layout__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .security-layout__header p {
    margin-top: 1rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-family: "PublicaSans-Regular";
  }

  .security-layout__header h2 {
      font-size: 20px;
      font-style: normal;
      color: var(--Black, #2A2A2A);
      font-weight: 400;
      line-height: 30px;
  }

  .security-common-layout__container > div {
    margin: 1rem 0;
  }

  .security-integrate-wrapper h2 {
    font-family: "PublicaSans-Bold";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 18rem;
  }

  .security-integrate-wrapper p {
    font-size: 1rem;
    font-style: normal;
    ont-weight: 400;
    line-height: 150%;
    margin-top: .5rem;
  }

  .security-option-container {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
  }

  .security-option-container > div {
    margin: 0 5rem;
  }

  .security-integrate-image-wrapper {
      width: 35rem;
      height: 27rem;
      background: var(--Light-Gray, #F4F5FB);
  }

  .handle-privacy-image-wrapper {
      width: 35rem;
      height: 27rem;
      background: var(--Light-Gray, #F4F5FB);
  }

  .security-layout__header {
      width: 20rem;
      text-align: center;
  }

  .security-common-layout__box {
      width: 210px;
      height: 166px;
      background: var(--Light-Gray, #F4F5FB);
  }
