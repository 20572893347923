.terms-of-use-container {
   margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 57rem;

    p {
        font-family: PublicaSans-Regular;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #2A2A2A;
        margin-top: 2rem;
    }

    h2 {
        font-family: PublicaSans-Bold;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
    }

    h3 {
        font-family: PublicaSans-Bold;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #2A2A2A;
        margin-top: 2rem;
    }

    h4 {
        font-family: PublicaSans-Bold;
        font-weight: 400;
    }

  }

  .terms-of-use-cont {
    margin-top: 2rem;
  }

  .first-terms-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .second-terms-cont ul {
    margin-top: 1rem;
  
  }

  .second-terms-cont ul li, .tenth-terms-cont ul li {
    list-style-type: none;
  }

  .second-terms-cont ul li a {
    font-family: PublicaSans-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #636467;
  }

  .second-terms-cont, 
  .third-terms-cont, 
  .fourth-terms-cont, 
  .fifth-terms-cont, 
  .sixth-terms-cont, 
  .seventh-terms-cont, 
  .eighth-terms-cont, 
  .ninth-terms-cont,
  .tenth-terms-cont, 
  .eleventh-terms-cont,
  .twelfth-terms-cont,
  .thirteenth-terms-cont {
    margin-top: 2rem;
  }

  .fourth-terms__one h3, 
  .fourth-terms__two h3, 
  .fourth-terms__three h3, 
  .fourth-terms__four h3 {
    margin-top: 2rem;
  }

  .fifth-terms__one h3, 
  .fifth-terms__two h3, 
  .fifth-terms__three h3 {
    margin-top: 2rem;
  }

  .sixth-terms__one h3, 
  .sixth-terms__two h3, 
  .sixth-terms__three h3 {
    margin-top: 2rem;
  }

  .tenth-terms__two ul {
    margin-top: 2rem;
  }



.tenth-tems__list-item {
    display: flex;
    gap: .2rem;

    span {
      margin-top: .2rem;
    }

    p {
      margin: 0;
    }
}

.list-item-1 {
    margin-left: .1rem;
}

.fourth-terms__four-title-mobile,  .ninth-terms__one-title-mobile {
   
}

@media (max-width: 960px) {
    .terms-of-use-container {
        width: 90%;

        h2 {
            font-size: 1.5rem;
        }
        h3 {
            font-size: 1.3rem;
        }

        

        p {
            font-size: 1rem;
        }

    }

    .terms-of-use-cont {
        margin-top: -.8rem;
    }

}

@media screen and (max-width: 768px) {
  .terms-of-use-container {
    h3 {
      font-size: 1.3rem;
    }
  }

  .fourth-terms__four-title, .ninth-terms__one-title {
      
  }
  
  .fourth-terms__four-title-mobile {
      width: 16rem;
      display: block;
  }

  .ninth-terms__one-title-mobile {
    width: 10rem;
    display: block;
  }


}

@media screen and (max-width: 425px) {
    .terms-of-use-header-wrapper {
        margin-top: -.8rem;
    }


}

