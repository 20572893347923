.footer-container {
  margin: 0 auto;
  width: 86.25rem;
}

.footer-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12rem;
  margin-top: 10rem;
}

.grid-contact-container {
  padding-left: 3rem;
}

.grid-contact-container a {
  text-decoration: none;
  color: #2A2A2A;
}

.footer-menu {
  display: flex;
  margin-top: 3rem;
}

.footer-menu p {
  font-family: "PublicaSans-Bold";
}

.footer-menu > div {
  margin: 0 3rem;
}

.product-list-container p,
.discover-list-container p,
.resources-list-container p {

}

.product-list-container ul,
.discover-list-container ul,
.resources-list-container ul {
  list-style-type: none;
}

.product-list-container ul li,
.discover-list-container ul li,
.resources-list-container ul li {
  margin: 0.9rem 0;
  cursor: pointer;
}

.prod-link,
.discover-link,
.resources-link {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.prod-link:hover,
.discover-link:hover,
.resources-link:hover {
  color: gray;
}


.product-list-container p,
.discover-list-container p,
.resources-list-container p {
  font-weight: 400;
}

.product-list-container ul,
.discover-list-container ul,
.resources-list-container ul {
  list-style-type: none;
}

.product-list-container ul li,
.discover-list-container ul li,
.resources-list-container ul li {
  margin: 0.9rem 0;
  cursor: pointer;
}

.group-one-contact p {
  font-family: "PublicaSans-Bold";
}

.s-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ff517b;
  border-radius: 3.5px;
  box-shadow: 4px 4px 0 0 #00bfd7;
  margin-bottom: 1rem;
  cursor: pointer;
}

.s-logo p {
  color: #ffff50;
  font-weight: bold;
  font-family: "PublicaSans-Bold";
  font-size: 2.2em;
  padding-bottom: 0.2rem;
}

.group-two-contact {

}

.group-three-contact {
  display: flex;
  margin-top: 1.5rem;
}

.group-three-contact img {
  width: 1.8rem;
  height: 1.8rem;
}

.icon-contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F4F5FB;
  margin: 0 0.7rem;
}

.policy-wrapper {
  margin-top: 8rem;
  display: flex;
}
.squib-policy-wrapper {
  width: 30.8rem;
}

.squib-policy-wrapper .year-squib {
   font-family: "PublicaSans-Bold";
   font-weight: 400;
}

.squib-policy-wrapper .link-imprint-privacy {
   color: #636467;
}

.store-wrapper {
  display: flex;
  align-items: center;
}

.switchlang-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.store-wrapper .store-ap,
.store-wrapper .store-gp {
  margin: 0 1rem 0 0;
}

.policy-wrapper .squib-policy-wrapper {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9rem;
  padding-bottom: 3.5rem;
  margin-top: 15px;
  position: relative;

}

.policy-translation-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 5.5rem;
  position: relative;
}

.policy-translation-wrapper .translation-footer {

}

.up-arrow {
  width: 3.75rem;
  height: 3.75rem;
}

.mobile-translation-in-policy {
  display: none;
}

@media screen and (max-width: 1285px) {
 .footer-info-wrapper {
  display: none;
 }

 .footer-container {
  width: 100%;
  padding: 0 1.5rem;
 }

 .mobile-translation-in-policy {
  display: block;
  margin-bottom: 1.5rem;
}

.up-scroll-mobile-wrapper {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

 .store-wrapper {
    margin-left: 1rem;
 }

 .policy-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
 }

 .squib-policy-wrapper {
   width: 100%;
   justify-content: center;
   align-items: center;
   margin-bottom: 1rem;
 }

 .squib-policy-wrapper .mobile-up-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5rem;
 }

 .mobile-up-arrow-wrapper .mobile-up-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .grid-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 0;
 }

 .squib-policy-wrapper .up-scroll-mobile {
    display: block;
 }

 .grid-contact-container .contact-info-email {
    text-decoration: none;
    color: #FF517B;
 }

}

@media screen and (max-width: 1285px) {
   .mobile-up-arrow.show {
      display: block;
   }
}

@media screen and (max-width: 960px) {
  .policy-wrapper {
    margin-top: 8rem;
  }
  .policy-translation-wrapper {
     justify-content: center;
     margin-top: -3rem;
     padding: 0;
  }

  .squib-policy-wrapper {
     width: 14rem;
      margin-bottom: 3rem;
  }

 .grid-contact-container .place-cont {
    padding: 0 3rem;
  }
}
