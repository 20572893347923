.imprint-privacy-header-container {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.imprint-privacy-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60rem;
}

@media (max-width: 960px) {
  .imprint-privacy-header-container {
      margin-top: 4rem;
  }

  .imprint-privacy-header-wrapper {
      width: 100%;
  }
}

@media (max-width: 768px) {
  .imprint-privacy-header-container {
      margin-top: 7.5rem;
  }
}

@media (max-width: 450px) {
  .imprint-privacy-header-wrapper h1 {
      line-height: normal;
  }
}
