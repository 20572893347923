.common-title {
  font-family: "PublicaSans-Bold";
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 3.75em;
  line-height: 3.75rem;
  padding-bottom: 0.5rem;
  text-align: center;
  transition: all 0.5s ease-in-out;
}


.common-title-mobile {
  font-family: "PublicaSans-Bold";
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 400;
  font-size: 3.75em;
  line-height: 3.75rem;
  padding-bottom: 0.5rem;
  text-align: center;
  transition: all 0.5s ease-in-out;
  display: none;
}

.common-subtitle {
  font-family: "PublicaSans-UltraLight";
  width: 67rem;
  font-style: normal;
  font-weight: 250;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
  color: #2a2a2a;
  margin-top: 2rem;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 960px) {
  .common-title {
    font-size: 3em;
    text-align: center;
  }

  .common-title.desktop {
    display: none;
  }

  .common-title.mobile {
    display: block;
  }

  .common-subtitle {
    font-size: 1.5rem;
    width: 50rem;
  }
}

@media screen and (max-width: 768px) {
  .common-title {
    font-size: 2rem;
  }

  .common-subtitle {
    font-weight: 250;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    width: 22rem;
    margin-bottom: 3rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {

  .common-subtitle {
    width: 20rem;
  }
}

@media screen and (max-width: 425px) {
  .common-title-mobile {
    display: block;
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
  }
}
