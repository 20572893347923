.voting-education-politics-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.line-two-vep {
  width: 10rem;
    height: 0.1648rem;
    background-color: #D1D5D9;
    margin-top: 1.5rem;
    border-radius: 1rem;
}
.line-one-vep {
  width: 10rem;
    height: 0.1648rem;
    background-color: #2A2A2A;
    margin-top: 1.5rem;
    border-radius: 1rem;
}

.voting-education-politics-container .voting-education-politics-wrapper,
.voting-education-politics-container .ve-img-wrapper {

}

.voting-education-politics-wrapper {
  display: flex;
  flex-direction: column;
  width: 35rem;
  padding: 2.2rem 0;
}

.voting-education-politics-container  .skin-harmony-brand-img {
  margin-left: 4rem;
  width: 8.0625rem;
}

.voting-education-politics-wrapper h1 {
  font-family: "PublicaSans-Bold";
  font-weight: 400;
  color: #2a2a2a;
  width: 22rem;
}

.voting-education-politics-wrapper h1,
.voting-education-politics-wrapper p {
  margin: 0 1.5rem 1.5rem 4rem;
}

.voting-education-politics-wrapper p {
  font-family: "PublicaSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #2a2a2a;
  width: 25rem;
}

.skin-harmony-brand-img {
  width: 12rem;
  margin-left: 1.4rem;
}

.mobile-voting-card-four {
  width: 27.5rem;
    height: 52rem;
}

@media (max-width: 1490px) {
  .voting-education-politics-container {
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
  }

  .voting-education-politics-container .voting-education-politics-wrapper {
    padding-bottom: 0rem;
}
}

@media (max-width: 1200px) {

  .voting-education-politics-wrapper {
    padding: 1.5rem 1rem;
 }

 .voting-education-politics-wrapper h1 {
   font-size: 1.25rem;
 }

 .voting-education-politics-wrapper p {
   font-size: 1rem;
 }

  .voting-education-politics-container .voting-education-politics-wrapper,
.voting-education-politics-container .ve-img-wrapper {
  margin: 0;
}
}

@media (max-width: 960px) {

  .voting-education-politics-wrapper {
    padding: 1.5rem 1rem;
 }

  .voting-education-politics-wrapper h1 {
    font-size: 1.25rem;
    width: 100%;
  }

  .voting-education-politics-wrapper p {
    font-size: 1rem;
    width: 100%;
  }

  .voting-education-politics-wrapper h1, .voting-education-politics-wrapper p {
    margin: 0 0rem 1.5rem 0rem;
  }
}

@media (max-width: 768px) {
  .voting-education-politics-container {
    margin-top: 1rem;
  }

  .voting-education-politics-wrapper {
    width: 22rem;
  }

  .voting-education-politics-wrapper h1 {
    margin-top: -2rem;
  }

  .voting-education-politics-wrapper p {
    margin-top: -1rem;
  }

  .voting-education-politics-container .skin-harmony-brand-img {
    width: 8rem;
    margin-left: 0;
  }

  .mobile-voting-card-four {
    width: 20.25rem;
    height: 37.5rem;
  }
}

@media (max-width: 480px) {
  .voting-education-politics-wrapper {
    width: 20rem;
  }
}
