.why-squib-feedback-container {
  margin-top: 0rem;
}

.content-container {
    display: flex;
    align-items: center;
    margin-top: 8rem;
}

.content-container .second-row {

}

.content-container > div {
    margin: 0 .7rem;
}

.why-squib-feedback-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fb-title {
  font-family: "PublicaSans-Bold";
  color: #2a2a2a;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 48px;
}

.fb-content {
  width: 30rem;
  height: 30rem;
  padding: 4rem 4rem;
  margin-bottom: 1.5rem;
  border-radius: 1.125rem;
  background: #fff;
}

.fb-content h2 {
    font-family: 'PublicaSans-Bold';
    color: #2a2a2a;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    color: #2a2a2a;
    font-style: normal;
    color: #2a2a2a;
}

.fb-content p {
  font-family: "PublicaSans-Regular";
  color: #2a2a2a;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 150%;
  margin: 1.2rem 0;
  font-style: normal;
}

.icon-letters {
  font-family: PublicaSans-Bold;
}

.ab-icon {
  margin-top: 1rem;
}

.lock-icon {
  margin-top: -1rem;
}


@media screen and (max-width: 1200px) {
  .content-container {
    flex-direction: column;
  }

  .second-row {
    margin-top: -2rem;
  }
}

@media screen and (max-width: 960px) {
  .why-squib-feedback-container {
    margin-top: 0rem;
  }

  .why-squib-feedback-wrapper h1 {
    font-size: 2.2rem;
    line-height: 3rem;
    text-align: center;
  }

  .why-squib-feedback-subtitle {
    line-height: 150%;
  }

  .fb-content h2 {
    font-size: 1.8rem;
    margin-top: 1rem;
  }

  .first-row {
    margin-top: 4rem;
  }

  .second-row {
    margin-top: -2rem;
  }

}

@media screen and (max-width: 768px) {
  .why-squib-feedback-wrapper h1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-top: -4rem;
  }

  .content-container {
    margin-top: 4rem;
  }

  .fb-content {
    width: 19.6875rem;
    height: auto;
    padding: 2.5rem;
  }

  .fb-content svg {
    width: 4.6rem;
  }

  .fb-content h2 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .fb-content p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    padding: 0 2rem 0 0;
  }

  .fb-content img {
    width: 4.0625rem;
  }

  .lock-icon {
    margin-top: 1rem;
  }

}

@media screen and (max-width: 576px) {
  .why-squib-feedback-wrapper h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
