.votingerrorscreen {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-weight: unset;
        font-family: "PublicaSans-Bold";
        font-size: 40px;
        max-width: 90%;
    }

    .voting-footer-wraper {
        position: absolute;
        bottom: 5%;
    }
}


@media screen and (max-width: 969px) {
    .votingerrorscreen {
        h1 {
            font-size: 24px;
        }
    }

}