.startup-nights-video-container {
  display: flex;
  justify-content: center;
}

.startup-nights-video-wrapper {
    width: 86rem;
    height: 48rem;
    margin-top: 10rem;
    border-radius: 40px;
    background: var(--Cyan-Light, #C7F3F9);
}

.startup-nights-video {
    width: 100%;
    height: 100%;
    border-radius: 40px;
}
