.use-cases-header-container {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
}

.use-cases-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 62rem;
}

@media screen and (max-width: 1285px) {
    .use-cases-header-container  {
        margin-top: 6rem;
    }

    .use-cases-header-container p {
        font-size: 1.5rem;
    }
}

@media (max-width: 960px) {
    .use-cases-header-container {
        margin-top: 6rem;
    }

    .use-cases-header-container p {
        width: 40rem;
    }
}

@media (max-width: 768px) {
    .use-cases-header-container {
        margin-top: 6rem;
    }

    .use-cases-header-container h1 {
        font-size: 2.25rem;
    }

    .use-cases-header-container p {
        font-size: 1.25rem;
        width: 25rem;
    }
}

@media (max-width: 450px) {
    .use-cases-header-container {
        margin-top: 7rem;
    }

    .use-cases-header-container h1 {
        font-size: 2.25rem;
        line-height: 2.5rem;
        letter-spacing: -0.5px;
    }

    .use-cases-header-container p {
        font-size: 1.25rem;
        width: 19.5rem;
        margin-top: 1rem;
        text-align: center;
        line-height: 1.5625rem;
    }
}

@media screen and (max-width: 425px) {
    .header-title-mobile-none {
        display: none;
    }
}
