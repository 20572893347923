.contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-form {
    margin-top: 7.75rem;
  }

  .header-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-top: 5rem;
  }

  .contact-h {
    font-family: "PublicaSans-Bold";
    letter-spacing: 0.5px;
    font-style: normal;
    font-weight: 400;
    font-size: 3.75em;
    line-height: 3.75rem;
  }

  .contact-sub {
    font-family: "PublicaSans-UltraLight";
    text-align: center;
    font-size: 1.875em;
    line-height: 2.25rem;
    color: #2a2a2a;
    padding-top: 1.875rem;
    transition: ease all .5s;
  }

  .checkbox-form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 1.6rem;
  }

  .check-box {
    width: 30px;
    height: 30px;
    background-color: #F4F5FB;
    border-radius: 3px;
    cursor: pointer;
    transition: ease all .5s;
    appearance: none;
  }

  .check-box[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='-2.1 -2.1 20 20' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.707 3.293a1 1 0 00-1.414 0L8 6.586 4.707 3.293a1 1 0 00-1.414 1.414L6.586 8l-3.293 3.293a1 1 0 101.414 1.414L8 9.414l3.293 3.293a1 1 0 001.414-1.414L9.414 8l3.293-3.293a1 1 0 000-1.414z'/%3e%3c/svg%3e");


  }

  .address-text .contact-info-header {
    font-family: "PublicaSans-Bold";
  }

  .contact-header .contact-info-email {
      text-decoration: none;
      border: none;
      color: gray;
 }

 .contact-header .contact-info-email:hover {
    color: gray;
  }

  .contact-header .linkedin-icon {
    margin-top: 1.25rem;
    margin-left: 0.5rem;
  }

  .check-box:checked + .check-text {
    font-family: "PublicaSans-Bold";
    color: #2a2a2a;
  }

  .check-text {
    text-indent: 0.625rem;
    margin-top: 0.1rem;
    font-family: "PublicaSans-Regular";
    color: #9b9b9b;
  }

  .check-text img {
    margin-left: 0.5rem;
  }

  #color {
    color: #9b9b9b;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .form-group input {
    font-family: PublicaSans-Regular;
    font-size: 0.9em;
    letter-spacing: 0.067em;
    overflow: hidden;
  }

  .input-container {
    position: relative;
    margin-bottom: 1.5rem;
  }

  .inp {
    background-color: #F4F5FB;
    width: 33rem;
    height: 3.75rem;
    font-family: "PublicaSans-Bold";
    font-size: 0.938em;
    color: #2a2a2a;
    margin-top: 0.2rem;
    border-radius: 0.625rem;
    text-indent: 1.75rem;
    border: none;
    outline: none;
  }

  .inp[type="text"]:focus {
    font-family: PublicaSans-Bold;
  }

  .inp[type="text"] {
    font-family: PublicaSans-Regular;
  }

  .placeholder {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  .placeholder.active {
    right: 1.75rem;
    top: 2.8rem;
    font-family: PublicaSans-Regular;
    color: #9b9b9b;
    font-size: 0.9em;
    letter-spacing: 0.067em;
    opacity: 1;
  }


  .message-field {
    position: relative;
  }

  #contact-form-area {
    display: flex;
    width: 528px;
    height: 215px;
    margin-top: .2rem;
    border: none;
    outline: none;
    padding: 1.1rem 1.8rem;
    border-radius: 10px;
    background-color: #F4F5FB;
    resize: none;
  }

  .message-field label {
    position: absolute;
    top: 2.55rem;
    left: 1.8rem;
    font-family: PublicaSans-Regular;
    font-size: 0.938em;
    color: #9B9b9b;
    pointer-events: none;
    transition: opacity 0.3s;
  }


  .message-field .label-message {
    opacity: 0;
  }

  #contact-form-area {
    font-size: 1em;
    font-family: PublicaSans-Bold;
  }

  .oblig {
    color: #6C6C6C;
    font-family: "PublicaSans-Regular";
    font-size: 0.938em;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .send-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    color: #fff;
  }

  .send-contact-btn {
    font-size: 1.25em;
    background-color: #000;
    color: #fff;
  }

  .contact-info {
    display: flex;
    justify-content: space-around;
    margin-top: 10.625rem;
    background-color: #fff;
    width: 1380px;
    padding: 3.75rem 0;
    border-radius: 2.25rem;
  }

  .contact-header,
  .squib-map {
    margin: 0 5rem;
  }

  .contact-header-text {
    font-family: 'PublicaSans-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #2A2A2A;
    display: none;
  }

  .contact-header div {
    margin-top: 1.25rem;
  }

  .address-text p {
    margin-bottom: 6px;
  }

  .squib-map-container {
    width: 48rem;
    height: 34rem;
  }

  .contact-info p {
    font-family: "PublicaSans-Regular";
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.0313rem;
    color: #2a2a2a;
  }

  .contact-info a {
    font-family: "PublicaSans-Regular";
    font-size: 1.25em;
    line-height: 1.5rem;
    letter-spacing: 0.0313rem;
    color: var(--pink);
    transition: ease all .5s;
  }

  .contact-info a:hover {
    color: var(--blue);
  }

  .error {
    border: 2px solid #F00;
  }

  .error-message {
    color: #F00;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.2rem;
  }

  .send-btn {
    font-family: "PublicaSans-Bold";
    font-size: 1.25em;
    letter-spacing: 0.0313rem;
    padding: 1rem 2rem;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    transition: ease all .5s;
  }

  .send-btn:hover {
    background-color: #000;
    color: #fff;
    border: none;
  }

  .msg-modal-wrapper {
    position: relative;
  }

  .msg-modal-wrapper h1 {
    font-family: PublicaSans-Bold;
    font-size: 2.4em;
    color: #2a2a2a;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }

  .msg-modal-wrapper p {
    text-align: center;
    font-family: PublicaSans-Regular;
    font-size: 1.4em;
    color: #2a2a2a;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }

  .msg-modal-wrapper .btn-form-modal-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }

  .btn-form-modal-wrapper .btn-form-modal {
    font-family: PublicaSans-Bold;
    font-size: 1.6em;
    letter-spacing: 0.0313rem;
    padding: 1.4rem 2rem;
    background-color: #2a2a2a;
    color: #fff;
    border: 3.5px solid transparent;
    outline: none;
    cursor: pointer;
    transition: ease all .5s;
  }

  .btn-form-modal-wrapper .btn-form-modal:hover {
    background-color: #fff;
    color: #2a2a2a;
    border: 3.5px solid #2a2a2a;
  }

  .close-icon-wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .close-icon-wrapper img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1380px) {
    .btn-form-modal-wrapper .btn-form-modal {
      display: block;
    }
    .contact-info {
      margin-top: 6rem;
      align-items: center;
      flex-direction: column;
    }

    .squib-map {
      margin-top: 5rem;
    }
  }

  @media screen and (max-width: 1286px) {
    .send-btn {
      display: block;
    }
  }


  @media screen and (max-width: 960px) {
    .contact-wrapper {
       margin-top: 7rem;
    }

   .google-map-wrapper  {
        padding: 0 5rem;
    }

    .contact-info {
      flex-direction: column;
      align-items: center;
      width: 84%;
      padding: 1.5rem 0;
    }

    .contact-header, .squib-map {
        margin: 0 3rem;
    }

    .squib-map-container {
      width: 18rem;
      height: 18rem;
    }

    .header-contact {
      width: 100%;
      padding-top: 7.75rem;
    }

    .contact-h {
      font-size: 1.5625em;
      line-height: 1.5625rem;
      text-align: center;
    }

    .contact-sub {
      font-size: 1.25rem;
      line-height: 1.5rem;
      text-align: center;
      padding: .625rem 1.25rem;
    }

    .placeholder.active {
      right: -5rem;
    }

    .contact-form {
      margin-top: 2.5rem;
    }

    .checkbox-form-group {
      padding: 0 1.25rem;
      justify-content: center;
      align-items: center;
    }

    .checkbox-form-group input {
      padding: 0 0.9rem;
    }

    .checkbox-form-group label {
      font-family: "PublicaSans-Regular";
      font-size: 1rem;
      text-indent: 0.3125rem;
      padding: 0 0.5rem;
    }

    .checkbox-form-group .check-text {
      letter-spacing: 0.0313rem;
      width: 25rem;
      transition: ease-in-out all .5s;
    }

    .form-group {
      padding: 0 1rem;
    }

    .form-group input {
      width: 27rem;
      transition: ease-in-out all .5s;
    }

    .form-group #contact-form-area {
      width: 27rem;
      font-size: 1rem;
      transition: ease-in-out all .5s;
    }

    .contact-form .oblig {
      font-size: .9375rem;
      font-family: "PublicaSans-Regular";
    }

    .contact-form .send-btn {
      font-size: 0.9375rem;
      letter-spacing: 0.0313rem;
      padding: 1rem 2rem;
    }

    .contact-info a {
      font-size: 0.9375rem;
    }

    .squib-map {
      margin-top: 3rem;
    }

    .squip-map-container {
      width: 5vw;
    }

    .send-btn-wrapper {
      margin-top: 1.25rem;
      justify-content: center;
      align-items: center;
    }

    .send-btn-wrapper .send-btn {
      font-size: 1rem;
      padding: 0.8rem 1.5rem;
    }

  }

  @media screen and (max-width: 768px) {
    .contact-wrapper {
      margin-top: 7rem;
    }

     .checkbox-form-group {
      padding: 0 6rem;
      justify-content: center;
      align-items: center;
    }

    .contact-info p {
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.0313rem;
      color: #2a2a2a;
    }

     .form-group {
      padding: 0 6rem;
     }

     .input-container label, .message-text {
        font-size: .9rem;
     }
  }

  @media screen and (max-width: 670px) {
    .modal-msg-wrapper h1 {
      text-align: center;
      font-size: 2rem;
   }
   .modal-msg-wrapper p {
      margin: 0 auto;
      font-size: 1.2rem;
      margin-top: 1.8rem;
      width: 12rem;
   }

   .btn-form-modal-wrapper .btn-form-modal {
      font-size: 1.2rem;
      padding: 1rem 1.8rem;
   }
  }

  @media screen and (max-width: 500px) {
    .check-box-wrapper {
     width: 20rem;
     transition: ease-in-out all .5s;
    }

    .form-group {
      margin: 0 auto;
    }

     .form-group input {
       width: 20rem;
       height: 3rem;
       transition: ease-in-out all .5s;
    }

    .input-container .placeholder.active {
      position: absolute;
      right: 2rem;
      top: 2.3rem;
    }

     .form-group #contact-form-area {
       width: 20rem;
        height: 10rem;
       font-size: 0.6875rem;
       transition: ease-in-out all .5s;
     }
  }
