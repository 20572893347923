.waitinglist-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 86.25rem;
    height: 28.75rem;
    border-radius: 2.25rem;
    background-color: #fff;
    margin-top: 12.625rem;
}

.waitinglist-home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 768px) {
    .waitinglist-home-container {
        margin-top: 64px;
        height: 30rem;
        text-align: center;
    }
}

@media screen and (max-width: 580px) {
    .waitinglist-home-container {
        margin-top: 4rem;
    }
}

@media screen and (max-width: 490px) {
    .waitinglist-home-container {
        height: 40rem;
        height: 32rem;
    }

    .waitinglist-comp-wrapper .waitinglist-desc {
        width: 14.75rem;
    }
}
