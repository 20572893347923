.customers-feedback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
  }

  .customers-feedback-container .customers-feedback-wrapper,
  .customers-feedback-container .customers-img-wrapper {

  }

  .customers-feedback-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2.2rem 0;
  }

  .customers-feedback-wrapper h1 {
    font-family: "PublicaSans-Bold";
    font-weight: 400;
    color: #2a2a2a;
    line-height: normal;
    width: 20rem;
  }

  .customers-feedback-wrapper h1,
  .customers-feedback-wrapper p {
    margin: 0 1.5rem 1.5rem 4rem;
    font-weight: 400;
  }

  .customers-feedback-wrapper .benedict-brand-img {
    margin-left: 4rem;
    width: 10.125rem;
  }

  .customers-feedback-wrapper p {
    font-family: "PublicaSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 150%;
    color: #2a2a2a;
    width: 27.625rem;
  }

  .benedict-brand-img {
    width: 12rem;
    margin-left: 1.4rem;
  }

  .startup-nights-img {
    width: 9rem;
    margin-left: 1.4rem;
  }

  .line-one, .line-two, .line-three,
  .line-four, .line-six,
  .line-seven, .line-eight
   {
    width: 2.3rem;
    height: 0.1648rem;
    background-color: #D1D5D9;
    margin-top: 1.5rem;
    border-radius: 1rem;
  }

 .line-five {
    width: 2.3rem;
    height: 0.1648rem;
    background-color: #2A2A2A;
    margin-top: 1.5rem;
    border-radius: 1rem;
  }

  .mobile-voting-card-one {
    width: 27.5rem;
    height: 52rem;
  }

  @media (max-width: 1490px) {
    .customers-feedback-container {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;
    }
    .customers-feedback-wrapper {
      padding-bottom: 0rem;

    }

  }

  @media (max-width: 960px) {

    customers-feedback-wrapper {
      width: 100%;
    }
    .customers-feedback-wrapper h1, .customers-feedback-wrapper p {
      margin: 0 0rem 1.5rem 0rem;
    }

    .customers-feedback-wrapper h1 {
      font-size: 1.25rem;
    }

    .customers-feedback-wrapper p {
      font-size: 1rem;
    }

    .customers-feedback-container .customers-feedback-wrapper,
    .customers-feedback-container .customers-img-wrapper {
    margin: 0;
  }
  }

  @media (max-width: 768px) {
    .customers-feedback-container {
      margin-top: 1rem;
    }

    .customers-feedback-wrapper .benedict-brand-img {
      width: 8rem;
      margin-left: 0;
    }

    .startup-nights-img {
      width: 7rem;
    }

    .customers-feedback-wrapper h1 {
      margin-top: -2rem;
    }

    .customers-feedback-wrapper p {
      width: 20rem;
      margin-top: -1rem;
    }

    .mobile-voting-card-one {
      width: 20.25rem;
      height: 37.5rem;
    }
  }

  @media (max-width: 480px) {
    .customers-feedback-wrapper {

    }

  }
