.untitled-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.untitled-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 86.25rem;
  height: 31rem;
  border-radius: 36px;
  background: #fff;
}

.untitled-title-wrapper h1 {
  font-size: 3.75rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  line-height: 3.75rem;
  letter-spacing: -1px;
  font-family: "PublicaSans-Bold";
  color: #2A2A2A;
}
.check-box-untitle {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.check-box-untitle p {
  margin-left: 1.5rem;
  font-size: 1.25rem;
  font-family: "PublicaSans-Regular";
  color: #2A2A2A;
}

@media screen and (max-width: 1390px) {
  .untitled-container {

  }
  .untitled-title-wrapper {
    height: 39rem;
    border-radius: 0;
  }
}

@media screen and (max-width: 1050px) {
  .untitled-container {

  }
  .untitled-content-container {
    width: 90%;
  }
}


@media screen and (max-width: 960px) {
  .untitled-container {

  }

  .untitled-container .untitled-title-wrapper {
    height: 32rem;
    border-radius: 0;
  }
  .untitled-title-wrapper h1 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .untitled-container {
    margin-top: 4rem;

  }
}

@media screen and (max-width: 768px) {
  .untitled-title-wrapper {
    height: 35rem;
  }
  .untitled-title-wrapper h1 {
    padding: 0 5rem;
    text-align: center;
    margin-top: 2rem;
  }

  .check-box-untitle {
    padding: 0 1.2rem;
  }

  .check-box-untitle p {
    font-size: 1rem;
  }

  .check-box-untitle img {
    width: 1rem;
    height: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .untitled-title-wrapper h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
