@import url("https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap");

@font-face {
  font-family: "PublicaSans-Ultralight";
  src: local("PublicaSans-Ultralight"), url(../fonts/PublicaSans-Ultralight.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "PublicaSans-Regular";
  src: local("PublicaSans-Regular"), url(../fonts/PublicaSans-Regular.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "PublicaSans-Bold";
  src: local("PublicaSans-PublicaSans-Bold"), url(../fonts/PublicaSans-Bold.woff) format("woff");
  font-display: swap;
}

:root {
  --pink: #ff517b;
  --blue: #00bfd7;
  --grey: #F4F5FB;
  --darkGrey: #636467;
  --black: #2a2a2a;
  --white: #ffffff;
  --yellow: #ffff50;
  --formError: #ff517b;
  --vh: calc(var(--vh, 1vh) * 100);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PublicaSans-Regular;
  color: var(--black);
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: var(--Light-Gray, #F6F6F9);
}

.App.votingapp {
  background-color: var(--white, #FFF);
}

.component-list-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 80vh;
  background-color: var(--white);
}

@media screen and (max-width: 969px) {
  .App {
    overflow-x: hidden;
  }


  .navbar-footer-display {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .cta-text {
    font-size: 30px;
    line-height: 30px;
    width: 80%;
  }
  .sub-text {
    font-size: 20px;
    line-height: 25px;
    width: 70%;
  }
}
