.competitive-feedback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

 .line-two-cf, .line-three-cf, .line-four-cf, .line-five-cf, .line-six-cf, .line-seven-cf, .line-eight-cf, .line-nine-cf, .line-ten-cf, .line-eleven-cf, .line-twelve-cf, .line-thirteen-cf, .line-fourteen-cf {
    width: 1.2rem;
    height: 0.1648rem;
    background-color: #D1D5D9;
    margin-top: 1.5rem;
    border-radius: 1rem;
}

.brand-logo-startup-nights img {
  width: 6rem;
}

.line-one-cf {
  width: 1.2rem;
  height: 0.1648rem;
  background-color: #2A2A2A;
  border-radius: 1rem;
}

.competitive-feedback-container .competitive-feedback-wrapper,
.competitive-feedback-container .comf-img-wrapper {

}

.competitive-feedback-wrapper {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2.2rem 0;
}

.competitive-feedback-wrapper h1 {
  width: 100%;
  font-family: "PublicaSans-Bold";
  font-weight: 400;
  color: #2a2a2a;
  width: 20rem;
}

.competitive-feedback-wrapper .startup-nights-img {
  margin-left: 4rem;
  width: 6.3125rem;
}

.competitive-feedback-wrapper h1,
.competitive-feedback-wrapper p {
  margin: 0 1.5rem 1.5rem 4rem;
}

.competitive-feedback-wrapper p {
  font-family: "PublicaSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #2a2a2a;
  width: 25rem;
}

.mobile-voting-card-three {
  width: 27.5rem;
    height: 52rem;
}


@media (max-width: 1490px) {
  .competitive-feedback-container {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 4rem;
  }

  .competitive-feedback-container .competitive-feedback-wrapper {
      padding-bottom: 0rem;
  }
}

@media (max-width: 960px) {

  .competitive-feedback-wrapper {
    padding: 1.5rem 1rem;
 }

 .competitive-feedback-wrapper h1, .competitive-feedback-wrapper p {
    margin: 0 0rem 1.5rem 0rem;
}

 .competitive-feedback-wrapper h1 {
   font-size: 1.25rem;
 }

 .competitive-feedback-wrapper p {
   font-size: 1rem;
 }

  .competitive-feedback-container .competitive-feedback-wrapper,
.competitive-feedback-container .comf-img-wrapper {
  margin: 0;
}
}

@media (max-width: 768px) {
  .competitive-feedback-container {
    margin-top: 0rem;
  }

  .competitive-feedback-wrapper {
      justify-content: center;
      align-items: center;
  }

  .competitive-feedback-wrapper h1 {
    margin-top: -2rem;
  }

  .competitive-feedback-wrapper p {
    width: 20rem;
    margin-top: -1rem;
  }

  .mobile-voting-card-three {
    width: 20.25rem;
    height: 37.5rem;
  }

  .competitive-feedback-wrapper .startup-nights-img {
    margin-left: -15rem;
    width: 4.7rem;
  }
}

@media (max-width: 480px) {
  .competitive-feedback-wrapper {

  }
}
