
.functionalities-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10rem auto;
}

.functionalities-main-container > div {
    margin: 0 1.2rem;
}

.functionalities-header-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
}

.functionalities-header-wrapper h1 {
    width: 35rem;
    font-family: 'PublicaSans-Bold';
    color: #2A2A2A;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    transition: font-size 0.5s ease, line-height 0.5s ease;
}

.functionalities-header-wrapper p {
    width: 30rem;
    margin-top: 1.5rem;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 150%;
    color: #2A2A2A;
    font-family: 'PublicaSans-Regular';
}

.funtionalities-img-wrapper img {
    transition: width 0.5s ease, height 0.5s ease;
    width: 16rem;
    margin-top: 3rem;
}

@media screen and (max-width: 1200px) {
    .functionalities-main-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0rem;
    }

    .functionalities-header-wrapper h1 {
        font-size: 1.25rem;
    }

    .functionalities-header-wrapper p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .functionalities-main-container {
        margin-top: -1.5rem;
    }

    .functionalities-header-wrapper h1 {
        width: 18rem;
        margin-top: 1rem;
        line-height: 1.5rem;
    }

    .functionalities-header-wrapper p {
        width: 20rem;
    }


    .funtionalities-img-wrapper img {
        transition: width 0.5s ease, height 0.5s ease;
        width: 14rem;
        height: 14rem;
        margin-top: 3rem;
    }
}
