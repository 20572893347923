.members-container {
    display: flex;
    justify-content: center;
}

.members-container > div {
    margin: 1rem  .8rem;
}

.members-wrapper {
    width: 22.1875rem;
    padding: 3.75rem;
    border-radius: 1.125rem;
    background-color: #fff;
}

.members-wrapper .members-cont .members-desc {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 150%;
}

.members-wrapper .members-cont .members-website-link {
    margin-top: 1rem;
    font-size: 1rem;
}

.members-wrapper .members-cont .members-author {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
}

.members-wrapper .members-cont .members-info {
    margin-left: .75rem;
    flex: 1;
}

.members-wrapper .members-cont .members-info p:nth-child(1) {
    font-family: "Oooh Baby";
    font-size: 1.875rem;
}

.members-wrapper .members-cont .members-avatar-wrapper {
    width: 3.9rem;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
}

.members-wrapper .members-cont .members-avatar-wrapper img {
    width: 3.9rem;
}

@media screen and (max-width: 1286px) {
    .members-wrapper .members-cont .members-info p:nth-child(2) {
        font-size: 1rem;
    }
}

@media screen and (max-width: 960px) {
    .members-wrapper .members-cont .members-desc {
        width: 16rem;
    }

    .members-wrapper .members-cont .members-author {
        margin-top: 2rem;
        position: relative;
    }

    .members-wrapper .members-cont .members-author .members-avatar-wrapper {
        position: absolute;
        top: -.8rem;
        left: 0;
    }

    .members-wrapper .members-cont .members-website-link {
        margin-top: -.7rem;
    }


    .members-wrapper .members-cont .members-info > p {
        width: 14rem;
        text-align: left;
    }

    .members-wrapper .members-cont .members-info > p {
        margin: -.5rem 3.8rem;
    }

    .members-wrapper .members-cont .members-info p:nth-child(2) {
        font-size: 1rem;
        margin-top: .3rem;
    }
}

@media screen and (max-width: 768px) {
    .members-wrapper {
        width: 19.6875rem;
        padding: 2.5rem;
    
    }

    .members-wrapper .members-brand-logo {
        margin-left: .3rem;
    }

    .members-website-link {
        margin-left: .4rem;
    
    }

    .members-wrapper .members-cont .members-desc {
        width: 14rem;
        text-align: left;
    }

    .members-wrapper .members-cont .members-author {
        margin-top: 2.1rem;
        position: relative;
    }

    .members-wrapper .members-cont .members-author .members-avatar-wrapper {
        position: absolute;
        top: -.8rem;
        left: 0;
    }

    .members-wrapper .members-cont .members-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .members-wrapper .members-cont .members-info > p {
        width: 14rem;
        text-align: left;
    }

    .members-wrapper .members-cont .members-info p:nth-child(1) {
        font-size: 1.5rem;
    }

    .members-wrapper .members-cont .members-info p:nth-child(2) {
        margin-top: .3rem;
    }
}

@media screen and (max-width: 430px) {
    .members-wrapper .members-cont .members-info {
        
    }
}
