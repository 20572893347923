.use-cases-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.upload-container {
  display: none;
}

.my-component {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.my-component.show {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .upload-container {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9rem;
    cursor: pointer;
  }
}
