.up-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    bottom: 3.3rem;
    left: -5.5rem;
  }

  .up-arrow-mobile-wrapper {
    display: none;
  }

  @media (max-width: 1285px) {
    .up-arrow-wrapper {
      display: none;
    }

    .up-arrow-mobile-wrapper {
      display: block;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: #F4F5FB;
      cursor: pointer;
    }

  }
