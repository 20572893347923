.voting-footer-wraper {
    display: flex;
    width: 100%;
    right:0;
    left:0;
    margin: auto;
    bottom: 0;
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "PublicaSans-Bold";
    align-self: flex-end;
    margin-top: 10px;
    /* position:absolute; */
    /* bottom: 5%; */
}
.voting-footer {
    font-family: "PublicaSans-Bold";
    width: 50%;
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;

    a {
        display: flex;
    }
}
.footer-text {
    font-family: "PublicaSans-Bold";
    font-size: 12px;
    line-height: 12px;
    /* font-weight: 400; */
    /* letter-spacing: 0px; */
    /* text-align: right; */
}
