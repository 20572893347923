.header-wraper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 52px;
  margin-bottom: 10px;
}

.header-wraper.isAbsolute {
position: absolute;
}

.multi-vote-carousel-area > .header-wraper {
  display: none;
  margin-top: 30px;
  margin-bottom: 20px;
}

.header-wraper .buttons {
  position: fixed;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.company-logo {
  height: 52px;
}

.squib-button {
  display: flex;
  text-decoration: none;
}
.squib-button button {
  position: relative;
  border-radius: 82px;
  height: 3rem;
  padding: 14px 24px;
  background-color: var(--black);
  color: #FFFFFF;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PublicaSans-Bold";
  font-size: 20px;
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 6px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 0;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 52px;
  border: 3px solid var(--black);
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: none;
  text-transform: none;
}

.squib-button button.secondarySquibButton {
  border: 3px solid #F6F6F9;
  color: var(--black);
  background-color: #F6F6F9;

}

.squib-button button:hover {
  background: #F6F6F9;
  border: 3px solid var(--black);
  color: #2A2A2A;
  margin-bottom: 6px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 0;
}


@media screen and (max-width: 969px) {
  .votingWrapper > .header-wraper {
      display: none;
  }

  .multi-vote-carousel-area > .header-wraper {
      display: flex;
  }

  .header-wraper .buttons {
    display: none;
  }
}