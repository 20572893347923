:root {
    --speed: 500ms;
}

.menu-header-container {
    display: none;
}


@media screen and (max-width: 1285px) {
    .menu-header-container {

    }

    .nav-mobile-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      position: fixed;
      border-radius: 3rem;
      top: 2rem;
      width: 90%;
      height: 3.75rem;
      margin-left: 5%;
      padding: 1rem 1.5rem;
      z-index: 999;
    }

    .nav-menu-mobile-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      width: 100%;
      height: calc(100 * var(--vh));
      background-color: var(--Light-Gray, #F6F6F9);
      position: fixed;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      z-index: 2;
    }

    .nav-menu-mobile {
      margin-top: 5rem;
    }

    .nav-mobile-box .squib-logo-mobile-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-menu-mobile-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 3rem;
      width: 90%;
      height: 3.75rem;
      padding: 1rem 1.5rem;
      position: sticky;
      top: 2rem;
    }

    .nav-menu-mobile-box .squib-logo-mobile-menu-wrapper {

    }

    .squib-logo {
      width: 5rem;
    }

    .nav-menu-mobile-cont {
      position: relative;
    }

    .world-icon {
      margin-right: 1rem;
      font-size: 1.5rem;
    }

  .nav-links-mobile {
    font-family: PublicaSans-Bold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2rem;
    cursor: pointer;
}

  .hamburger-icon-wrapper {
    color: #000;
    z-index: 1000;
  }

  .cb-mobile-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 14rem;
    padding: 1rem 2rem;
    border-radius: 3rem;
    background: #000;
    color: #fff;
  }

  .nav-item-mobile ul li {
    text-align: center;
    margin: 2rem 0;
  }

  .nav-item-mobile ul li {
    text-decoration: none;
    list-style: none;
  }

  .nav-item-mobile ul li a {
    text-decoration: none;
    list-style: none;
    font-size: 1rem;
}

.nav-menu-mobile-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.nav-menu-mobile-btn-wrapper .nav-menu-mobile-btn {
   font-size: 1rem;
   background-color: #2A2A2A;
   color: #fff;
}

.nav-menu-mobile-btn-wrapper .nav-menu-mobile-btn:hover {
  background-color: #F6F6F9;
}

  .CV-mobile-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CV-mobile-btn {
    display: block;
    text-align: center;
    width: 10rem;

  }

  .translation-mobile-cont-wrapper {
    margin-top: 1.5rem;
  }

  .footer-contact-mobile-wrapper {
    margin-top: 1.25rem;
  }

  .nav-menu-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: block;
    height: 100%;
  }

  .nav-item-mobile {
    text-align: center;
    margin: 3rem 0;
    text-decoration: none;
  }

  .nav-item-mobile .nav-links-mobile {
    font-size: 1.5rem;
    color: #000;
    text-decoration: none;
    list-style: none;
    transition: 0.3s;
  }

    .menu-header-container {
        display: block;
    }

    .open-close-icon {
        width: 2rem;
        height: 2rem;
        margin-top: .6rem;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 1000;
      }

      .open-close-icon span {
        display: block;
        position: absolute;
        height: 0.15rem;
        width: 83%;
        background: #000;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

      }

      .open-close-icon span:nth-child(1) {
        top: 0.1rem;
      }

      .open-close-icon span:nth-child(2) {
        top: 0.6rem;
      }

      .open-close-icon span:nth-child(3) {
        top: 1.1rem;
      }

      .open-close-icon .open:nth-child(1) {
        top: 0.625rem;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      .open-close-icon .open:nth-child(2) {
        opacity: 0;
        left: -3.75rem;
      }

      .open-close-icon .open:nth-child(3) {
        top: 0.625rem;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }

      .impressum-datenschutz-link {
        text-align: center;
      }

      .mobile-translation-policy-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .policy-mobile-btn.hidde .up-arrow-mobile-wrapper {
        display: none;
      }

      .squib-policy-wrapper strong{
        font-family: PublicaSans-Bold;
      }

      a {
        color: var(--darkGrey);
      }
}

@media (min-width: 960px) {
  .mobile-translation-policy-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
