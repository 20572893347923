.translation-btn-mobile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .de-btn {
    margin-right: 1rem;
  }

  .en-btn {
    margin-left: 1rem;
  }

  .translation-btn-mobile-wrapper button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
  }

  .translation-btn-mobile-wrapper .vertical-line {
    width: 0.0625rem;
    height: 1.2rem;
    background-color: #000;
  }

  .translation-btn-mobile-wrapper .tbtn {
    border: none;
    cursor: pointer;
    background-color: #ff517b;
    padding: 0.5313rem;
    border-radius: 1.875rem;
    display: flex;
    box-shadow: 3px 3px 0 #00bfd7;
  }

  .tbtn h2 {
    color: #feff4f;
  }

  .tbtn.active {
    display: none;
  }
