.final-slide-dev {
    position: relative;
    display: flex;
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Thanks-text {
    font-family: "PublicaSans-Bold";
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.5px;
    opacity: 0; 
    animation: fadeIn 0.3s linear forwards;
    animation-delay: 1.2s;
    z-index: 1000;
    position: absolute;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

/* .lottie-container {
    border: 1px solid black;
} */