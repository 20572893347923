.use-cases-header-style {
  width: 35rem;
  text-align: center;
}

@media (max-width: 960px) {
  .getintouch-use-cases-container{
    margin-top: 4rem;
  }

  .use-cases-header-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .use-cases-header-style h1 {
    padding: 0 5rem;
    font-size: 1.8rem;
    letter-spacing: -0.25px;
  }

  .getintouch-use-cases-wrapper .cbtn-wrapper .btn-get-in-touch {

}

}

@media (max-width: 768px) {
  .use-cases-header-style h1 {
      padding: 0 8rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.25px;

  }
}

@media (max-width: 545px) {
  .use-cases-header-style h1 {
      padding: 0 3rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.25px;
  }
}
