.testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.testimonials-wrapper {
    display: flex;
}
